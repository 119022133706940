import { useState, useEffect, useRef } from 'react';
import {
  Box,
  Typography,
  TextField,
  Paper,
  Skeleton,
  alpha,
  InputAdornment,
  Collapse,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { suggestionApi } from 'src/api/suggestion';
import {
  PetFeelingResponse,
  ProductRecommendation,
} from 'src/types/suggestion';
import ProductRecommendations from './ProductRecommendations';
import Iconify from 'src/components/iconify/Iconify';

interface PetFeelingInputProps {
  profileId: string;
  onSubmitSuccess?: () => void;
}

interface ConversationMessage {
  type: 'input' | 'response';
  text: string;
  timestamp: string;
  isMedicalRelated?: boolean;
  productRecommendations?: ProductRecommendation[];
  isTemporary?: boolean;
  isConversationClosing?: boolean;
}

export default function PetFeelingInput({
  profileId,
  onSubmitSuccess,
}: PetFeelingInputProps) {
  const [petFeeling, setPetFeeling] = useState('');
  const [submittingFeeling, setSubmittingFeeling] = useState(false);
  const [validationError, setValidationError] = useState<string | null>(null);
  const [isLoadingResponse, setIsLoadingResponse] = useState(false);
  const [petFeelingResponse, setPetFeelingResponse] =
    useState<PetFeelingResponse | null>(null);
  const [conversationHistory, setConversationHistory] = useState<
    ConversationMessage[]
  >([]);
  const [isFollowUpQuestion, setIsFollowUpQuestion] = useState(false);
  const [showResponseContainer, setShowResponseContainer] = useState(false);
  const [petName, setPetName] = useState<string | undefined>(undefined);
  const [responseMessages, setResponseMessages] = useState<
    ConversationMessage[]
  >([]);
  const [showFeedback, setShowFeedback] = useState(false);
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { enqueueSnackbar } = useSnackbar();

  // Helper function to detect if a response contains a question
  const containsQuestion = (text: string): boolean => {
    // Check for question marks
    if (text.includes('?')) return true;

    // Check for common question phrases in Polish
    const questionPhrases = [
      'jakie',
      'które',
      'czy',
      'jak',
      'kiedy',
      'gdzie',
      'co tam',
      'dlaczego',
      'powiedz mi',
      'opowiedz',
      'opisz',
      'wymień',
    ];

    const lowercaseText = text.toLowerCase();
    return questionPhrases.some((phrase) =>
      lowercaseText.includes(phrase.toLowerCase()),
    );
  };

  // Update conversation history and response messages when a new message is added
  useEffect(() => {
    // Get only the response messages for display
    const filteredMessages = conversationHistory.filter(
      (message) => message.type === 'response',
    );
    setResponseMessages(filteredMessages);
  }, [conversationHistory]);

  // Add a useEffect to handle conversation closure
  useEffect(() => {
    // Get the last message in the conversation history
    const lastMessage =
      conversationHistory.length > 0
        ? conversationHistory[conversationHistory.length - 1]
        : null;

    // Check if the last message is a system response and indicates conversation closure
    if (
      lastMessage &&
      lastMessage.type === 'response' &&
      lastMessage.isConversationClosing
    ) {
      // Show feedback section instead of progress bar
      setShowFeedback(true);
      setSubmittingFeeling(false);

      // Set a timeout to clear the conversation history after 30 seconds if no feedback is given
      const timeoutId = window.setTimeout(() => {
        if (!feedbackSubmitted) {
          // Clear conversation history
          setConversationHistory([]);
          // Hide the response container
          setShowResponseContainer(false);
          // Reset the feedback state
          setShowFeedback(false);
        }
      }, 30000); // 30 seconds

      // Clear the timeout if the component unmounts or if a new message is added
      return () => {
        window.clearTimeout(timeoutId);
        setShowFeedback(false);
      };
    } else {
      // Reset feedback state if the last message is not a closing message
      setShowFeedback(false);
    }
  }, [conversationHistory, feedbackSubmitted]);

  useEffect(() => {
    if (!showFeedback) {
      return;
    }
  }, [showFeedback]);

  const handleFeelingSubmit = async () => {
    if (!petFeeling.trim()) {
      return;
    }

    try {
      setSubmittingFeeling(true);
      setValidationError(null);
      setIsLoadingResponse(true);

      // Show the response container immediately for the loading animation
      setShowResponseContainer(true);

      // Add user input to conversation history
      const newUserMessage: ConversationMessage = {
        type: 'input',
        text: petFeeling,
        timestamp: new Date().toISOString(),
      };

      setConversationHistory((prev) => [...prev, newUserMessage]);

      // Create a temporary skeleton response to show immediately
      setPetFeelingResponse({
        input: petFeeling,
        response: '',
        timestamp: new Date().toISOString(),
        isMedicalRelated: false,
      });

      let response;

      // If this is a follow-up question (we already have a conversation history with at least one exchange)
      // Use the conversation endpoint
      if (conversationHistory.length >= 2) {
        // At least one user input and one system response
        // Convert our conversation history to the format expected by the API
        const formattedHistory = conversationHistory
          .filter((msg) => !msg.isTemporary)
          .map((msg) => ({
            role: msg.type === 'input' ? 'user' : 'assistant',
            content: msg.text,
          }));

        response =
          await suggestionApi.suggestionControllerSubmitPetConversation({
            petConversationRequestDto: {
              profileId: profileId,
              input: petFeeling,
              conversationHistory: formattedHistory,
            },
          });
      } else {
        // First message in conversation
        response = await suggestionApi.suggestionControllerSubmitPetFeeling({
          petFeelingInputDto: {
            profileId: profileId,
            feeling: petFeeling,
          },
        });
      }

      setSubmittingFeeling(false);
      setIsLoadingResponse(false);

      // Store the conversation in the database
      // if (onSubmitSuccess) {
      //   onSubmitSuccess();
      // }

      if (response.data.isValid) {
        enqueueSnackbar('Pet feeling submitted successfully', {
          variant: 'success',
        });

        // Update the response with the actual data
        setPetFeelingResponse({
          input: petFeeling,
          response: response.data.response || '',
          timestamp: new Date().toISOString(),
          isMedicalRelated: response.data.isMedicalRelated || false,
          isConversationClosing: response.data.isConversationClosing || false,
          productRecommendations: response.data.productRecommendations,
        });

        // Store the pet name if available
        if (response.data.petName) {
          setPetName(response.data.petName);
        }

        // Add system response to conversation history
        const newSystemMessage: ConversationMessage = {
          type: 'response',
          text: response.data.response || '',
          timestamp: new Date().toISOString(),
          isMedicalRelated: response.data.isMedicalRelated || false,
          productRecommendations: response.data.productRecommendations || [],
          isConversationClosing: response.data.isConversationClosing || false,
        };

        setConversationHistory((prev) => [...prev, newSystemMessage]);

        // Check if the response contains a question
        const hasQuestion = containsQuestion(response.data.response || '');
        setIsFollowUpQuestion(hasQuestion);

        // If it's a follow-up question, focus the input field
        if (hasQuestion) {
          setTimeout(() => {
            if (inputRef.current) {
              inputRef.current.focus();
            }
          }, 500);
        }

        setPetFeeling('');

        // Call the success callback if provided
        // if (onSubmitSuccess) {
        //   onSubmitSuccess();
        // }
      } else {
        setValidationError(response.data.reason || 'Invalid input');

        // Add the polite message as a special system message that will be displayed
        // but not stored in the conversation history
        const specialMessage: ConversationMessage = {
          type: 'response',
          text: response.data.reason || 'Invalid input',
          timestamp: new Date().toISOString(),
          isTemporary: true, // Mark as temporary so we know not to store it
        };

        // Display the message temporarily
        setResponseMessages([specialMessage]);

        // Keep the response container visible to show the message
        setShowResponseContainer(true);

        // Clear the input field
        setPetFeeling('');

        // Don't store this in the actual conversation history
        setPetFeelingResponse(null);
      }
    } catch (error) {
      console.error('Error submitting pet feeling:', error);

      // Add the error message as a special system message
      const errorMessage: ConversationMessage = {
        type: 'response',
        text: 'Przepraszamy, wystąpił błąd. Spróbuj ponownie za chwilę.',
        timestamp: new Date().toISOString(),
        isTemporary: true,
      };

      // Display the message temporarily
      setResponseMessages([errorMessage]);

      // Keep the response container visible to show the message
      setShowResponseContainer(true);

      // Clear the input field
      setPetFeeling('');

      // Don't store this in the actual conversation history
      setPetFeelingResponse(null);
    } finally {
      setSubmittingFeeling(false);
      setIsLoadingResponse(false);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && petFeeling.trim() && !submittingFeeling) {
      handleFeelingSubmit();
    }
  };

  const getInputPlaceholder = () => {
    if (isFollowUpQuestion) {
      return 'Type your answer here...';
    }
    return 'e.g., energetic and playful, tired, anxious...';
  };

  const handleFeedback = async (isHelpful: boolean) => {
    try {
      // Call the API to save feedback
      await suggestionApi.suggestionControllerSubmitFeedback({
        profileId,
        isHelpful,
      });

      // Mark feedback as submitted
      setFeedbackSubmitted(true);

      // Clear conversation after a short delay
      setTimeout(() => {
        // Clear conversation history
        setConversationHistory([]);
        // Hide the response container
        setShowResponseContainer(false);
        // Reset the feedback state
        setShowFeedback(false);
        setFeedbackSubmitted(false);
      }, 1500);
    } catch (error) {
      console.error('Failed to submit feedback:', error);
      // Still clear the conversation even if feedback submission fails
      setConversationHistory([]);
      setShowResponseContainer(false);
      setShowFeedback(false);
      setFeedbackSubmitted(false);
    }
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <Box sx={{ position: 'relative', zIndex: 2 }}>
        <TextField
          fullWidth
          placeholder={getInputPlaceholder()}
          value={petFeeling}
          size="medium"
          inputRef={inputRef}
          onChange={(e) => {
            // Limit to 50 characters
            if (e.target.value.length <= 50) {
              setPetFeeling(e.target.value);
              // Clear validation error when user starts typing again
              if (validationError) {
                setValidationError(null);
              }
            }
          }}
          onKeyPress={handleKeyPress}
          disabled={submittingFeeling}
          inputProps={{ maxLength: 50 }}
          error={!!validationError || petFeeling.length > 50}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <LoadingButton
                  variant="contained"
                  onClick={handleFeelingSubmit}
                  disabled={submittingFeeling || !petFeeling.trim()}
                  loading={submittingFeeling}
                  sx={{
                    height: '100%',
                    borderRadius: '50%',
                  }}
                >
                  S
                </LoadingButton>
              </InputAdornment>
            ),
            sx: {
              pr: 1,
              borderBottomLeftRadius:
                responseMessages.length > 0 ? 0 : undefined,
              borderBottomRightRadius:
                responseMessages.length > 0 ? 0 : undefined,
            },
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              borderBottomLeftRadius:
                responseMessages.length > 0 ? 0 : undefined,
              borderBottomRightRadius:
                responseMessages.length > 0 ? 0 : undefined,
            },
            backgroundColor: '#fdfbfa',
          }}
        />
      </Box>

      {/* Pet Feeling Response with Animation - Only show system responses */}
      <Collapse
        in={showResponseContainer || responseMessages.length > 0}
        timeout={500}
        sx={{
          position: 'relative',
          marginTop: '-6px', // Overlap with the input field
          zIndex: 1,
          backgroundColor: 'white',
        }}
      >
        <Box>
          <Paper
            elevation={1}
            sx={{
              p: 2,
              bgcolor:
                responseMessages.length > 0
                  ? '#052330'
                  : (theme) => alpha(theme.palette.background.default, 0.8),
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              borderTop: '1px solid',
              borderTopColor: 'divider',
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {/* Show loading skeleton when loading */}
              {isLoadingResponse && responseMessages.length === 0 && (
                <Box sx={{ width: '100%' }}>
                  <Box
                    sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}
                  >
                    <Box sx={{ width: '100%' }}>
                      <Skeleton animation="wave" height={20} width="90%" />
                      <Skeleton animation="wave" height={20} width="75%" />
                    </Box>
                  </Box>
                </Box>
              )}

              {/* Show only the latest response message */}
              {responseMessages.length > 0 && !isLoadingResponse && (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  {/* Show the latest response */}
                  <Typography
                    variant="body2"
                    sx={{
                      color: '#50ceff',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    {responseMessages[responseMessages.length - 1].text}
                  </Typography>

                  {/* Show product recommendations if available */}
                  {(() => {
                    const lastMessage =
                      responseMessages.length > 0
                        ? responseMessages[responseMessages.length - 1]
                        : null;
                    const recommendations =
                      lastMessage?.productRecommendations || [];

                    return recommendations.length > 0 ? (
                      <Collapse in={recommendations.length > 0} timeout={500}>
                        <ProductRecommendations
                          recommendations={recommendations}
                          embedded
                        />
                      </Collapse>
                    ) : null;
                  })()}
                </Box>
              )}

              {/* Show loading skeleton when adding a new response */}
              {isLoadingResponse && responseMessages.length > 0 && (
                <Box sx={{ width: '100%' }}>
                  <Box
                    sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}
                  >
                    <Box sx={{ width: '100%' }}>
                      <Skeleton
                        animation="wave"
                        height={20}
                        width="90%"
                        sx={{ bgcolor: 'rgba(255, 255, 255, 0.2)' }}
                      />
                      <Skeleton
                        animation="wave"
                        height={20}
                        width="75%"
                        sx={{ bgcolor: 'rgba(255, 255, 255, 0.2)' }}
                      />
                    </Box>
                  </Box>
                </Box>
              )}

              {/* Show feedback section */}
              {showFeedback && (
                <Box
                  sx={{
                    width: '100%',
                    mt: 2,
                    p: 2,
                    borderRadius: 1,
                    bgcolor: 'background.neutral',
                    boxShadow: (theme) => theme.customShadows.z8,
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      textAlign: 'center',
                      mb: 2,
                      fontWeight: 'medium',
                    }}
                  >
                    Czy ta rozmowa była pomocna?
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      gap: 2,
                    }}
                  >
                    <LoadingButton
                      variant="contained"
                      color="success"
                      onClick={() => handleFeedback(true)}
                      startIcon={
                        <Iconify icon="eva:checkmark-circle-outline" />
                      }
                      sx={{
                        px: 3,
                        py: 1,
                      }}
                    >
                      Tak
                    </LoadingButton>
                    <LoadingButton
                      variant="contained"
                      color="error"
                      onClick={() => handleFeedback(false)}
                      startIcon={<Iconify icon="eva:close-circle-outline" />}
                      sx={{
                        px: 3,
                        py: 1,
                      }}
                    >
                      Nie
                    </LoadingButton>
                  </Box>
                </Box>
              )}
            </Box>
          </Paper>
        </Box>
      </Collapse>
    </Box>
  );
}
