import { AppBar, Toolbar, useTheme } from '@mui/material';
import { memo } from 'react';
// config
import { HEADER } from '../../../config-global';
// components
import { NavSectionHorizontal } from '../../../components/nav-section';
//
import { useCurrentVisitContext } from 'src/sections/@dashboard/visit/hooks/useCurrentVisitContext';
import { DARK_BACKGROUND } from '../../../theme/palette';
import getNavConfig from './config-navigation';
import { useUserContext } from 'src/auth/useUserContext';

// ----------------------------------------------------------------------

function NavHorizontal() {
  const theme = useTheme();
  const { data: visitData } = useCurrentVisitContext();
  const { isProfessionalRole } = useUserContext();

  return (
    <AppBar
      component="nav"
      color="transparent"
      sx={{
        boxShadow: theme.customShadows.z8,
        top: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
      }}
    >
      <Toolbar
        sx={{
          backgroundColor: DARK_BACKGROUND.default,
        }}
      >
        <NavSectionHorizontal data={getNavConfig(visitData?.profile?.id, isProfessionalRole)} />
      </Toolbar>
    </AppBar>
  );
}

export default memo(NavHorizontal);
