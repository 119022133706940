import { UserApi } from 'woofwoof-api';
import { axiosBaseUrl } from './client';
import axiosBackendClient from './client';

const userApi = new UserApi(
  {
    basePath: axiosBaseUrl,
    isJsonMime: () => false,
  },
  undefined,
  //@ts-ignore
  axiosBackendClient,
);

export { userApi };
