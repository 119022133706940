import { SuggestionsApi, ValidatePetDtoPetCategoryEnum, ConversationMessageRoleEnum } from 'woofwoof-api';
import client, { axiosBaseUrl } from './client';
import { ProductRecommendation } from 'src/types/suggestion';

// Create the base API instance
const baseSuggestionApi = new SuggestionsApi(
  {
    basePath: axiosBaseUrl,
    isJsonMime: () => false,
  },
  undefined,
  //@ts-ignore
  client,
);

// Create a typed wrapper around the API
export const suggestionApi = {
  // Get all suggestions for the authenticated user
  suggestionControllerFindAll: async () => {
    const response = await baseSuggestionApi.suggestionControllerFindAll();
    // We know the backend returns an array of suggestions
    return {
      ...response,
      data: response.data as unknown as any[],
    };
  },

  // Get all suggestions for a specific profile
  suggestionControllerFindAllByProfileId: async (params: {
    profileId: string;
  }) => {
    const response =
      await baseSuggestionApi.suggestionControllerFindAllByProfileId(params);
    // We know the backend returns an array of suggestions for the profile
    return {
      ...response,
      data: response.data as unknown as any[],
    };
  },

  // Get a specific suggestion by ID
  suggestionControllerFindOne: async (params: { id: string }) => {
    const response = await baseSuggestionApi.suggestionControllerFindOne(
      params,
    );
    // We know the backend returns a single suggestion object
    return {
      ...response,
      data: response.data as unknown as any,
    };
  },

  // Delete a suggestion
  suggestionControllerRemove: async (params: { id: string }) => {
    return await baseSuggestionApi.suggestionControllerRemove(params);
  },

  // Manually trigger generation of suggestions for all profiles
  suggestionControllerGenerateAll: async () => {
    const response = await baseSuggestionApi.suggestionControllerGenerateAll();
    return {
      ...response,
      data: response.data as unknown as { message: string },
    };
  },

  // Generate daily suggestions for a specific profile
  suggestionControllerGenerate: async (params: {
    generateSuggestionRequestDto: {
      profileId: string;
    };
  }) => {
    const response = await baseSuggestionApi.suggestionControllerGenerate(params);
    return {
      ...response,
      data: response.data as unknown as any,
    };
  },

  // Get the latest pet fact
  suggestionControllerGetLatestPetFact: async () => {
    const response =
      await baseSuggestionApi.suggestionControllerGetLatestPetFact();
    return {
      ...response,
      data: response.data as unknown as any,
    };
  },

  // Generate a new pet fact
  suggestionControllerGeneratePetFact: async () => {
    const response =
      await baseSuggestionApi.suggestionControllerGeneratePetFact();
    return {
      ...response,
      data: response.data,
    };
  },

  // Mark a pet fact as read
  suggestionControllerMarkPetFactAsRead: async (id: string) => {
    const response =
      await baseSuggestionApi.suggestionControllerMarkPetFactAsRead({
        id,
      });
    return {
      ...response,
      data: response.data as unknown as any,
    };
  },

  // Validate if a pet breed and category are valid
  suggestionControllerValidatePet: async (params: {
    validatePetDto: {
      petCategory: string;
      breed: string;
    };
  }) => {
    // Convert the string petCategory to the required enum type
    const petCategory =
      params.validatePetDto.petCategory.toLowerCase() as ValidatePetDtoPetCategoryEnum;

    const response = await baseSuggestionApi.suggestionControllerValidatePet({
      validatePetDto: {
        petCategory,
        breed: params.validatePetDto.breed,
      },
    });
    return {
      ...response,
      data: response.data as { isValid: boolean; message: string },
    };
  },

  // Generate a product recommendation for a profile
  suggestionControllerGenerateProductRecommendation: async (params: {
    generateSuggestionRequestDto: {
      profileId: string;
    };
  }) => {
    const response =
      await baseSuggestionApi.suggestionControllerGenerateProductRecommendation(
        {
          generateSuggestionRequestDto: params.generateSuggestionRequestDto,
        },
      );
    return {
      ...response,
      data: response.data as unknown as any,
    };
  },

  // Submit pet feeling input
  suggestionControllerSubmitPetFeeling: async (params: {
    petFeelingInputDto: {
      profileId: string;
      feeling: string;
    };
  }) => {
    const response = await baseSuggestionApi.suggestionControllerSubmitPetFeeling(
      params,
    );
    // We know the backend returns a response with isValid, response, and isMedicalRelated
    return {
      ...response,
      data: response.data as unknown as {
        isValid: boolean;
        response?: string;
        isMedicalRelated?: boolean;
        reason?: string;
        petName?: string;
        productRecommendations?: ProductRecommendation[];
        isConversationClosing?: boolean;
      },
    };
  },

  // Submit a follow-up conversation message
  suggestionControllerSubmitPetConversation: async (params: {
    petConversationRequestDto: {
      profileId: string;
      input: string;
      conversationHistory: Array<{ role: string; content: string }>;
    };
  }) => {
    // Convert the role strings to the enum values expected by the API
    const formattedParams = {
      petConversationRequestDto: {
        ...params.petConversationRequestDto,
        conversationHistory: params.petConversationRequestDto.conversationHistory.map(msg => ({
          role: msg.role === 'user' 
            ? ConversationMessageRoleEnum.User 
            : ConversationMessageRoleEnum.Assistant,
          content: msg.content
        }))
      }
    };
    
    try {
      const response = await baseSuggestionApi.suggestionControllerSubmitPetConversation(
        formattedParams
      );
      
      return {
        ...response,
        data: response.data as {
          isValid: boolean;
          response?: string;
          isMedicalRelated?: boolean;
          reason?: string;
          petName?: string;
          productRecommendations?: ProductRecommendation[];
          isConversationClosing?: boolean;
        },
      };
    } catch (error) {
      console.error('Error submitting pet conversation:', error);
      throw error;
    }
  },

  // Submit feedback for a conversation
  suggestionControllerSubmitFeedback: async (params: {
    profileId: string;
    isHelpful: boolean;
  }) => {
    try {
      const response = await baseSuggestionApi.suggestionControllerSaveConversationFeedback({
        conversationFeedbackDto: {
          profileId: params.profileId,
          isHelpful: params.isHelpful,
        },
      });
      
      return {
        ...response,
        data: response.data,
      };
    } catch (error) {
      console.error('Error submitting conversation feedback:', error);
      throw error;
    }
  },
};
