import {
  Box,
  Card,
  Typography,
  Chip,
  Button,
  alpha,
  useTheme,
} from '@mui/material';
import { Link } from 'react-router-dom';
import Iconify from 'src/components/iconify';
import { ProductRecommendation } from 'src/types/suggestion';

type ProductRecommendationCardProps = {
  item: ProductRecommendation;
  embedded?: boolean;
};

function ProductRecommendationCard({
  item,
  embedded,
}: ProductRecommendationCardProps) {
  const theme = useTheme();

  const getCategoryColor = (category?: string) => {
    // Handle undefined or null category
    if (!category) {
      return theme.palette.primary.main; // Default color
    }
    
    switch (category.toLowerCase()) {
      case 'supplement':
        return theme.palette.success.main;
      case 'food':
        return theme.palette.warning.main;
      case 'toy':
        return theme.palette.info.main;
      case 'accessory':
        return theme.palette.secondary.main;
      case 'grooming':
        return theme.palette.primary.main;
      default:
        return theme.palette.primary.main;
    }
  };

  return (
    <Card
      sx={{
        p: 2,
        boxShadow: theme.customShadows.z1,
        borderLeft: `4px solid ${getCategoryColor(item.category)}`,
        bgcolor: !embedded
          ? alpha(getCategoryColor(item.category), 0.05)
          : undefined,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
        {item.imageUrl && (
          <Box
            component="img"
            src={item.imageUrl}
            alt={item.title}
            sx={{
              width: 80,
              height: 80,
              objectFit: 'cover',
              borderRadius: 1,
              border: `1px solid ${theme.palette.divider}`,
            }}
          />
        )}
        <Box sx={{ flexGrow: 1 }}>
          <Box
            sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}
          >
            <Typography
              variant="subtitle2"
              sx={{ textTransform: 'capitalize' }}
            >
              {item.title}
            </Typography>
            <Chip
              label={item.category}
              size="small"
              sx={{
                height: 20,
                fontSize: '0.625rem',
                backgroundColor: alpha(getCategoryColor(item.category), 0.2),
                color: getCategoryColor(item.category),
              }}
            />
          </Box>
          <Typography variant="body2" sx={{ mb: 1 }}>
            {item.description}
          </Typography>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="caption"
              sx={{ color: 'text.primary', fontStyle: 'italic' }}
            >
              {item.relevance}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              {item.price && (
                <Typography variant="subtitle2" color="primary">
                  ${item.price}
                </Typography>
              )}
              {item.purchaseUrl && (
                <Button
                  component={Link}
                  to={item.purchaseUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  size="small"
                  variant="contained"
                  startIcon={<Iconify icon="mdi:cart" />}
                >
                  Buy
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Card>
  );
}

interface ProductRecommendationsProps {
  recommendations: ProductRecommendation[];
  title?: string;
  containerSx?: any;
  embedded?: boolean;
}

export default function ProductRecommendations({
  recommendations,
  title = '',
  containerSx = {},
  embedded = false,
}: ProductRecommendationsProps) {
  const theme = useTheme();

  if (!recommendations || recommendations.length === 0) {
    return null;
  }

  return (
    <Box sx={{ mt: 2, ...containerSx }}>
      {title && (
        <Typography variant="subtitle1" sx={{ mb: 1 }}>
          {title}
        </Typography>
      )}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {recommendations.map((recommendation, index) => (
          <Box key={index}>
            {!embedded && recommendation.context && (
              <Typography
                variant="body2"
                sx={{
                  mb: 1,
                  color: embedded ? theme.palette.primary.main : 'text.primary',
                }}
              >
                {recommendation.context}
              </Typography>
            )}
            <ProductRecommendationCard
              item={recommendation}
              embedded={embedded}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export { ProductRecommendationCard };
