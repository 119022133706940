// @mui
import {
  Alert,
  Avatar,
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';

import { format } from 'date-fns';
import { pl } from 'date-fns/locale';
import { useNavigate } from 'react-router';
import VetsiIcon from 'src/assets/icons/VetsiIcon';
import Iconify from 'src/components/iconify';
import { useResponsiveBreakpoints } from 'src/hooks/ueResponsiveBreakpoints';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { getAgeFormat } from 'src/utils/getAgeFormat';
import { GetProfileDto, GetVisitById } from 'woofwoof-api';
import ProfileCardEmptyProfileButton from './ProfileCardEmptyProfileButton';
import ProfilesCardMenu from './ProfilesCardMenu';
// ----------------------------------------------------------------------

type ProfileCardProps = {
  profile: GetProfileDto;
  isSmall?: boolean;
};

export default function ProfileCard({ isSmall, profile }: ProfileCardProps) {
  const navigate = useNavigate();
  const theme = useTheme();

  const { isXs, isSm, isMd } = useResponsiveBreakpoints();

  const navigateToBooking = (profileId: GetProfileDto['id']) =>
    navigate(PATH_DASHBOARD.general.booking.schedule(profileId));

  const navigateToProfile = (profileId: GetProfileDto['id']) =>
    navigate(PATH_DASHBOARD.general.profile.suggestions(profileId));

  const isProfileNotEmpty = profile?.dob && profile?.gender && profile?.weight;

  return (
    <Box
      sx={{
        borderRadius: 2,
        background: theme.palette.background.default,
        boxShadow: theme.customShadows.z8,
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          borderTopRightRadius: theme.spacing(2),
          borderTopLeftRadius: theme.spacing(2),
          height: '160px',
          position: 'relative',
          background: theme.palette.primary.main,
          backgroundImage: 'url(/assets/background/pattern-v3.svg)',
          backgroundSize: 'cover',
        }}
      >
        {isSmall && isProfileNotEmpty && (
          <Box
            sx={{
              position: 'absolute',
              top: theme.spacing(1),
              right: theme.spacing(1),
            }}
          >
            <ProfilesCardMenu profile={profile} buttonBackground={isSmall} />
          </Box>
        )}
      </Box>

      <Box
        sx={{
          position: 'relative',
          height: isSmall ? 'auto' : '64px',
          mb: 2,
          padding: '0 24px',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '-64px',
            left: theme.spacing(3),
            right: theme.spacing(3),
            height: isSmall ? 'auto' : '128px',
          }}
        >
          <Grid container item columnSpacing={3} rowSpacing={isSmall ? 2 : 0}>
            <Grid item xs={isSmall ? 12 : 'auto'}>
              <Box
                sx={{
                  position: 'relative',
                  display: 'flex',
                  justifyContent: isSmall ? 'center' : 'normal',
                }}
              >
                <Avatar
                  src={profile?.avatar || '/assets/avatars/avatar4.png'}
                  sx={{
                    width: '128px',
                    height: '128px',
                    border: `2px solid ${theme.palette.background.neutral}`,
                    backgroundColor: theme.palette.grey[400],
                  }}
                />
              </Box>
            </Grid>
            {!isSmall && (
              <>
                <Grid item xs={isSmall ? 12 : true}>
                  <Stack
                    sx={{
                      justifyContent: 'flex-end',
                      height: '100%',
                    }}
                  >
                    <Typography variant="h4">{profile?.name}</Typography>
                    <Typography sx={{ color: theme.palette.text.secondary }}>
                      {profile.breed || 'Zwierzak'}
                    </Typography>
                  </Stack>
                </Grid>
                {(profile.futureVisit || profile.lastVisit) && (
                  <Grid item xs={isSmall ? 12 : 'auto'}>
                    <Stack
                      sx={{
                        flexDirection: 'row',
                        alignItems: 'flex-end',
                        height: '100%',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 2,
                        }}
                      >
                        <Iconify
                          icon="ic:sharp-calendar-month"
                          color={theme.palette.primary.main}
                        />
                        {profile.futureVisit ? (
                          <Typography variant="body2">
                            {`Najbliższe e-spotkanie: ${format(
                              new Date(
                                (profile.futureVisit as GetVisitById).startTime,
                              ),
                              'd MMMM',
                              {
                                locale: pl,
                              },
                            )} o godzinie ${format(
                              new Date(
                                (profile.futureVisit as GetVisitById).startTime,
                              ),
                              'H:mm',
                              {
                                locale: pl,
                              },
                            )}`}
                          </Typography>
                        ) : (
                          <Typography variant="body2">
                            {`Ostatnie e-spotkanie: ${format(
                              new Date(
                                (profile.lastVisit as GetVisitById).startTime,
                              ),
                              'd MMMM',
                              {
                                locale: pl,
                              },
                            )} o godzinie ${format(
                              new Date(
                                (profile.lastVisit as GetVisitById).startTime,
                              ),
                              'H:mm',
                              {
                                locale: pl,
                              },
                            )}`}
                          </Typography>
                        )}
                      </Box>
                    </Stack>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Box>
        {isSmall && (
          <Grid container spacing={2} sx={{ paddingTop: 2 }}>
            <Grid
              item
              xs={isSmall ? 12 : true}
              sx={{
                paddingTop: isSmall ? '68px !important' : 2,
              }}
            >
              <Stack
                sx={{
                  justifyContent: isSmall ? 'center' : 'flex-end',
                  alignItems: isSmall ? 'center' : 'stretch',
                  height: '100%',
                }}
              >
                <Typography variant="h4">{profile?.name}</Typography>
                <Typography sx={{ color: theme.palette.text.secondary }}>
                  {profile.breed || 'Zwierzak'}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        )}
      </Box>
      {isSmall && (
        <Box
          sx={{
            p: 3,
            paddingTop: 0,
            display: 'flex',
          }}
        >
          <Grid container item spacing={2}>
            {profile?.description && (
              <Grid item xs={12}>
                <Typography
                  sx={{
                    wordWrap: 'break-word',
                  }}
                >
                  {profile?.description}
                </Typography>
              </Grid>
            )}

            <Grid item xs={isSmall ? 12 : 3}>
              <Stack sx={{ gap: 2 }}>
                {isProfileNotEmpty && (
                  <>
                    <Stack
                      sx={{
                        gap: 2,
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <VetsiIcon />
                      <Typography variant="body2">
                        Wiek: {getAgeFormat(profile?.dob)}
                      </Typography>
                    </Stack>
                    <Stack
                      sx={{
                        gap: 2,
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <VetsiIcon />
                      <Typography variant="body2">
                        Płeć: {profile.gender}
                      </Typography>
                    </Stack>
                    <Stack
                      sx={{
                        gap: 2,
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <VetsiIcon />
                      <Typography variant="body2">{`Waga: ${profile.weight}kg`}</Typography>
                    </Stack>
                    {profile?.chipNumber && (
                      <Stack
                        sx={{
                          gap: 2,
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <VetsiIcon />
                        <Typography variant="body2">
                          Chip: {profile.chipNumber}
                        </Typography>
                      </Stack>
                    )}
                  </>
                )}
                {isSmall && (profile.futureVisit || profile.lastVisit) && (
                  <Stack
                    sx={{
                      gap: 2,
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <VetsiIcon />
                    {profile.futureVisit ? (
                      <Typography variant="body2">
                        {`Najbliższe e-spotkanie: ${format(
                          new Date(
                            (profile.futureVisit as GetVisitById).startTime,
                          ),
                          'd MMMM',
                          {
                            locale: pl,
                          },
                        )} o godzinie ${format(
                          new Date(
                            (profile.futureVisit as GetVisitById).startTime,
                          ),
                          'H:mm',
                          {
                            locale: pl,
                          },
                        )}`}
                      </Typography>
                    ) : (
                      <Typography variant="body2">
                        {`Ostatnie e-spotkanie: ${format(
                          new Date(
                            (profile.lastVisit as GetVisitById).startTime,
                          ),
                          'd MMMM',
                          {
                            locale: pl,
                          },
                        )} o godzinie ${format(
                          new Date(
                            (profile.lastVisit as GetVisitById).startTime,
                          ),
                          'H:mm',
                          {
                            locale: pl,
                          },
                        )}`}
                      </Typography>
                    )}
                  </Stack>
                )}
              </Stack>
            </Grid>
          </Grid>
        </Box>
      )}
      {Boolean(!isSmall) && Boolean(isProfileNotEmpty) && (
        <Box sx={{ p: 3, paddingTop: 0 }}>
          <Grid container item spacing={2}>
            {profile?.description && (
              <Grid item xs={12}>
                <Typography sx={{ wordWrap: 'break-word' }}>
                  {profile?.description}
                </Typography>
              </Grid>
            )}
            <Grid item xs={isSmall ? 12 : 3}>
              <Stack sx={{ gap: 2 }}>
                <Stack
                  sx={{
                    gap: 2,
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <VetsiIcon />
                  <Typography variant="body2">
                    Wiek: {getAgeFormat(profile?.dob)}
                  </Typography>
                </Stack>
                <Stack
                  sx={{
                    gap: 2,
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <VetsiIcon />
                  <Typography variant="body2">
                    Płeć: {profile.gender}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={isSmall ? 12 : 3}>
              <Stack sx={{ gap: 2 }}>
                <Stack
                  sx={{
                    gap: 2,
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <VetsiIcon />
                  <Typography variant="body2">{`Waga: ${profile.weight}kg`}</Typography>
                </Stack>
                {profile?.chipNumber && (
                  <Stack
                    sx={{
                      gap: 2,
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <VetsiIcon />
                    <Typography variant="body2">
                      Chip: {profile.chipNumber}
                    </Typography>
                  </Stack>
                )}
              </Stack>
            </Grid>
            {isSmall && (profile.futureVisit || profile.lastVisit) && (
              <Grid item xs={12}>
                <Stack sx={{ gap: 2 }}>
                  <Stack
                    sx={{
                      gap: 2,
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <VetsiIcon />
                    {profile.futureVisit ? (
                      <Typography variant="body2">
                        {`Najbliższe e-spotkanie: ${format(
                          new Date(
                            (profile.futureVisit as GetVisitById).startTime,
                          ),
                          'd MMMM',
                          {
                            locale: pl,
                          },
                        )} o godzinie ${format(
                          new Date(
                            (profile.futureVisit as GetVisitById).startTime,
                          ),
                          'H:mm',
                          {
                            locale: pl,
                          },
                        )}`}
                      </Typography>
                    ) : (
                      <Typography variant="body2">
                        {`Ostatnie e-spotkanie: ${format(
                          new Date(
                            (profile.lastVisit as GetVisitById).startTime,
                          ),
                          'd MMMM',
                          {
                            locale: pl,
                          },
                        )} o godzinie ${format(
                          new Date(
                            (profile.lastVisit as GetVisitById).startTime,
                          ),
                          'H:mm',
                          {
                            locale: pl,
                          },
                        )}`}
                      </Typography>
                    )}
                  </Stack>
                </Stack>
              </Grid>
            )}
            <Grid
              item
              xs={isSmall ? 12 : 6}
              sx={{
                marginTop: isSmall ? 1 : 0,
              }}
            >
              <Stack
                sx={{
                  gap: isSmall ? 2 : 2,
                  flexDirection: isXs || isSm || isMd ? 'column' : 'row',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-end',
                  height: 1,
                  marginLeft: isSmall ? -3 : 0,
                  marginRight: isSmall ? -3 : 0,
                  paddingLeft: isSmall ? 3 : 0,
                  paddingRight: isSmall ? 3 : 0,
                  paddingTop: isSmall ? 3 : 0,
                  paddingBottom: 0,
                  borderTop: isSmall
                    ? `1px dashed ${theme.palette.divider}`
                    : 'none',
                }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  fullWidth={isSmall}
                  onClick={() => navigateToProfile(profile.id)}
                >
                  Przejdź do profilu
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth={isSmall}
                  onClick={() => navigateToBooking(profile.id)}
                >
                  Zaplanuj e-spotkanie
                </Button>
                {!isSmall && <ProfilesCardMenu profile={profile} />}
                {isSmall && (
                  <Box sx={{ float: 'inline-end' }}>
                    <ProfilesCardMenu profile={profile} />
                  </Box>
                )}
              </Stack>
            </Grid>
          </Grid>
        </Box>
      )}
      {!isProfileNotEmpty && (
        <Box sx={{ p: 3, paddingTop: 0 }}>
          <Alert
            sx={{
              borderBottomLeftRadius: isSmall ? 0 : theme.spacing(1),
              borderBottomRightRadius: isSmall ? 0 : theme.spacing(1),
            }}
            severity="error"
            action={
              !isSmall && (
                <ProfileCardEmptyProfileButton
                  profile={profile}
                  isSmall={isSmall}
                />
              )
            }
          >
            Uzupełnij dodatkowe dane profilu, aby usprawnić przebieg
            e-spotkania.
          </Alert>
          {isSmall && (
            <Box
              sx={{
                p: 2,
                pt: 0,
                borderBottomLeftRadius: isSmall ? theme.spacing(1) : 0,
                borderBottomRightRadius: isSmall ? theme.spacing(1) : 0,
                backgroundColor: theme.palette.error.lighter,
              }}
            >
              <ProfileCardEmptyProfileButton
                profile={profile}
                isSmall={isSmall}
              />
            </Box>
          )}
        </Box>
      )}
      {isSmall && isProfileNotEmpty && (
        <Stack
          sx={{
            gap: isSmall ? 2 : 2,
            flexDirection: isXs || isSm || isMd ? 'column' : 'row',
            marginTop: 'auto',
            px: 3,
            py: 3,
            borderTop: isSmall ? `1px dashed ${theme.palette.divider}` : 'none',
          }}
        >
          <Button
            sx={{ minWidth: isXs || isSm || isMd ? 1 : 'auto' }}
            variant="contained"
            color="secondary"
            size="large"
            fullWidth={isSmall}
            onClick={() => navigateToProfile(profile.id)}
          >
            Przejdź do profilu
          </Button>
          <Button
            sx={{ minWidth: isXs || isSm || isMd ? 1 : 'auto' }}
            variant="contained"
            color="primary"
            size="large"
            fullWidth={isSmall}
            onClick={() => navigateToBooking(profile.id)}
          >
            Zaplanuj e-spotkanie
          </Button>
          {!isSmall && <ProfilesCardMenu profile={profile} />}
        </Stack>
      )}
    </Box>
  );
}
