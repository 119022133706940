import client, { axiosBaseUrl } from './client';
import { TemplatesApi } from 'woofwoof-api';

// Define enums that match the backend values
export enum Categories {
  veterinarian = 'veterinarian',
  dietitian = 'dietitian',
  behaviorist = 'behaviorist',
  physiotherapist = 'physiotherapist',
}

export enum TemplateType {
  INITIAL_ASSESSMENT = 'initial_assessment',
  FOLLOW_UP = 'follow_up',
  MEAL_PLAN = 'meal_plan',
  GENERAL = 'general',
}

export interface Template {
  id?: string;
  name: string;
  description: string;
  category: Categories;
  templateType: TemplateType;
  content: string;
  isShared: boolean;
  createdBy: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface CreateTemplateDto {
  name: string;
  description: string;
  category: Categories;
  templateType: TemplateType;
  content: string;
  isShared: boolean;
}

export interface UpdateTemplateDto extends Partial<CreateTemplateDto> {}

export interface GetTemplatesQueryDto {
  category?: Categories;
  templateType?: TemplateType;
  includeShared?: boolean;
  name?: string;
}

// Create the API instance
const templatesApiInstance = new TemplatesApi(
  {
    basePath: axiosBaseUrl,
    isJsonMime: () => false,
  },
  undefined,
  //@ts-ignore
  client,
);

// Create a wrapper with the same interface as before
export const templateApi = {
  getTemplates: async (query: GetTemplatesQueryDto = {}): Promise<Template[]> => {
    const response = await templatesApiInstance.templateControllerGetTemplates({
      category: query.category,
      templateType: query.templateType,
      includeShared: query.includeShared,
      name: query.name,
    });
    return response.data as unknown as Template[];
  },

  getTemplateById: async (templateId: string): Promise<Template> => {
    const response = await templatesApiInstance.templateControllerGetTemplateById({
      id: templateId,
    });
    return response.data as unknown as Template;
  },

  createTemplate: async (createTemplateDto: CreateTemplateDto): Promise<Template> => {
    const response = await templatesApiInstance.templateControllerCreateTemplate({
      createTemplateDto,
    });
    return response.data as unknown as Template;
  },

  updateTemplate: async (templateId: string, updateTemplateDto: UpdateTemplateDto): Promise<Template> => {
    // Need to cast to any to avoid TypeScript errors with optional properties
    const response = await templatesApiInstance.templateControllerUpdateTemplate({
      id: templateId,
      updateTemplateDto: updateTemplateDto as any,
    });
    return response.data as unknown as Template;
  },

  deleteTemplate: async (templateId: string): Promise<{ success: boolean }> => {
    const response = await templatesApiInstance.templateControllerDeleteTemplate({
      id: templateId,
    });
    return { success: true };
  },
};
