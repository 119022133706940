import { useEffect, useState } from 'react';
import {
  Box,
  Card,
  Typography,
  Divider,
  Chip,
  Stack,
  Button,
  alpha,
  useTheme,
  CircularProgress,
  Link,
  TextField,
  Paper,
  IconButton,
  Skeleton,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Iconify from 'src/components/iconify';
import { suggestionApi } from 'src/api/suggestion';
import { feedbackApi } from 'src/api/feedback';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import {
  Suggestion,
  ActionItem,
  Recommendation,
  LocationRecommendation,
  ProductRecommendation,
} from 'src/types/suggestion';
import { fToNow, fDateTime } from 'src/utils/formatTime';
import EmptyContent from 'src/components/empty-content';
import PetFeelingInput from './PetFeelingInput';
import ProductRecommendations from './ProductRecommendations';

// Helper function to convert API response to our Suggestion type
const mapResponseToSuggestion = (response: any): Suggestion => {
  return {
    _id: response._id || response.id,
    profileId: response.profileId,
    suggestion: {
      actionItems: response.suggestion.actionItems.map((item: any) => ({
        title: item.title,
        description: item.description,
        priority: item.priority.toLowerCase() as 'high' | 'medium' | 'low',
        type: item.title,
      })),
      recommendations: response.suggestion.recommendations.map((rec: any) => ({
        type: rec.title,
        description: rec.description,
        category: rec.category || 'other',
      })),
      locationRecommendations: response.suggestion.locationRecommendations
        ? response.suggestion.locationRecommendations.map((rec: any) => ({
            type: rec.title,
            description: rec.description,
            category: rec.category,
            relevance: rec.relevance,
          }))
        : [],
      productRecommendations: response.suggestion.productRecommendations
        ? response.suggestion.productRecommendations.map((rec: any) => ({
            title: rec.title,
            description: rec.description,
            productId: rec.productId,
            imageUrl: rec.imageUrl,
            price: rec.price,
            purchaseUrl: rec.purchaseUrl,
            category: rec.category,
            relevance: rec.relevance,
            context: rec.context,
          }))
        : [],
    },
    generatedAt: response.generatedAt,
    createdAt: response.createdAt,
    readAt: response.isRead ? new Date().toISOString() : undefined,
  };
};

export default function ProfileSuggestions() {
  const { profileId } = useParams<{ profileId: string }>();
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [loading, setLoading] = useState(true);
  const [generating, setGenerating] = useState(false);
  const [generatingDailySuggestions, setGeneratingDailySuggestions] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const [latestSuggestion, setLatestSuggestion] = useState<Suggestion | null>(
    null,
  );

  useEffect(() => {
    fetchSuggestions();
  }, [profileId]);

  const fetchSuggestions = async () => {
    try {
      setLoading(true);
      setError(null);

      let data: Suggestion[] = [];

      if (profileId) {
        const response =
          await suggestionApi.suggestionControllerFindAllByProfileId({
            profileId,
          });
        if (response.data) {
          data = (response.data as any[]).map(mapResponseToSuggestion);
          // Sort by createdAt date (newest first)
          data.sort(
            (a, b) =>
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
          );
        }
      } else {
        const response = await suggestionApi.suggestionControllerFindAll();
        if (response.data) {
          data = (response.data as any[]).map(mapResponseToSuggestion);
          // Sort by createdAt date (newest first)
          data.sort(
            (a, b) =>
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
          );
        }
      }

      setSuggestions(data);
      // If we successfully loaded suggestions, set the latest one
      if (data.length > 0) {
        setLatestSuggestion(data[0]);
      }
      return data; // Return data for promise chaining
    } catch (error) {
      console.error('Error fetching suggestions:', error);
      setError('Unable to load suggestions.');
      // Don't show a snackbar here to avoid too many notifications
      throw error; // Re-throw for promise chaining
    } finally {
      setLoading(false);
    }
  };

  const generateProductRecommendation = async () => {
    if (!profileId) return;

    try {
      setGenerating(true);
      setError(null);

      await suggestionApi.suggestionControllerGenerateProductRecommendation({
        generateSuggestionRequestDto: { profileId },
      });

      enqueueSnackbar('Product recommendation generated successfully', {
        variant: 'success',
      });
      fetchSuggestions();
    } catch (error) {
      console.error('Error generating product recommendation:', error);
      setError(
        'Failed to generate product recommendation. Please try again later.',
      );
      enqueueSnackbar('Failed to generate product recommendation', {
        variant: 'error',
      });
    } finally {
      setGenerating(false);
    }
  };

  const generateDailySuggestions = async () => {
    if (!profileId) return;

    try {
      setGeneratingDailySuggestions(true);
      setError(null);

      await suggestionApi.suggestionControllerGenerate({
        generateSuggestionRequestDto: { profileId },
      });

      enqueueSnackbar('Daily suggestions generated successfully', {
        variant: 'success',
      });
      fetchSuggestions();
    } catch (error) {
      console.error('Error generating daily suggestions:', error);
      setError(
        'Failed to generate daily suggestions. Please try again later.',
      );
      enqueueSnackbar('Failed to generate daily suggestions', {
        variant: 'error',
      });
    } finally {
      setGeneratingDailySuggestions(false);
    }
  };

  const handleFeedbackSubmit = async (
    suggestionId: string,
    isHelpful: boolean,
  ) => {
    try {
      await feedbackApi.submitFeedback({
        suggestionId,
        isHelpful,
      });

      enqueueSnackbar('Thank you for your feedback!', { variant: 'success' });
    } catch (error) {
      console.error('Error submitting feedback:', error);
      enqueueSnackbar('Failed to submit feedback', { variant: 'error' });
    }
  };

  useEffect(() => {
    if (suggestions.length > 0) {
      setLatestSuggestion(suggestions[0]);
    }
  }, [suggestions]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Card sx={{ p: 3, height: '100%' }}>
        <EmptyContent
          title="Error loading suggestions"
          description={error}
          img="/assets/illustrations/illustration_empty_content.svg"
        />
        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="contained"
            onClick={fetchSuggestions}
            startIcon={<Iconify icon="eva:refresh-fill" />}
          >
            Retry
          </Button>
        </Box>
      </Card>
    );
  }

  if (suggestions.length === 0) {
    return (
      <Card sx={{ p: 3, height: '100%' }}>
        <EmptyContent
          title="No Suggestions Available"
          description="No pet care suggestions have been generated yet."
          img="/assets/illustrations/illustration_empty_content.svg"
        />
      </Card>
    );
  }

  return (
    <Card>
      <Box
        sx={{
          p: 3,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6">Pet Care Suggestions</Typography>
        <Box sx={{ p: 3, display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={generateDailySuggestions}
            disabled={generatingDailySuggestions}
            startIcon={
              generatingDailySuggestions ? (
                <CircularProgress size={20} />
              ) : (
                <Iconify icon="mdi:calendar-refresh" />
              )
            }
          >
            {generatingDailySuggestions ? 'Generating...' : 'Generate Daily Suggestions'}
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={generateProductRecommendation}
            disabled={generating}
            startIcon={
              generating ? (
                <CircularProgress size={20} />
              ) : (
                <Iconify icon="mdi:shopping" />
              )
            }
          >
            {generating ? 'Generating...' : 'Get Product Recommendations'}
          </Button>
        </Box>
      </Box>

      <Divider sx={{ borderStyle: 'dashed' }} />

      {/* Pet Feeling Input Component */}
      <Box sx={{ p: 3 }}>
        {profileId && (
          <PetFeelingInput
            profileId={profileId}
            onSubmitSuccess={fetchSuggestions}
          />
        )}
      </Box>

      {[...suggestions].map((suggestion, index) => (
        <Box key={suggestion._id} sx={{ p: 3, pb: 1 }}>
          {/* Action Items Section */}
          {suggestion.suggestion.actionItems &&
            suggestion.suggestion.actionItems.length > 0 && (
              <>
                <Typography variant="subtitle1" gutterBottom>
                  Action Items
                </Typography>

                <Stack spacing={2} sx={{ mb: 3 }}>
                  {suggestion.suggestion.actionItems.map((item, itemIndex) => (
                    <ActionItemCard key={`action-${itemIndex}`} item={item} />
                  ))}
                </Stack>
              </>
            )}

          {/* Recommendations Section */}
          {suggestion.suggestion.recommendations &&
            suggestion.suggestion.recommendations.length > 0 && (
              <>
                <Typography variant="subtitle1" gutterBottom>
                  Recommendations
                </Typography>

                <Stack spacing={2} sx={{ mb: 2 }}>
                  {suggestion.suggestion.recommendations.map(
                    (item, itemIndex) => (
                      <RecommendationCard
                        key={`rec-${itemIndex}`}
                        item={item}
                      />
                    ),
                  )}
                </Stack>
              </>
            )}

          {/* Product Recommendations Section */}
          {suggestion.suggestion.productRecommendations &&
            suggestion.suggestion.productRecommendations.length > 0 && (
              <ProductRecommendations
                recommendations={suggestion.suggestion.productRecommendations}
                title={`Products • ${fDateTime(suggestion.createdAt)}`}
                containerSx={{ mb: 2 }}
              />
            )}

          {/* Location Recommendations Section */}
          {suggestion.suggestion.locationRecommendations &&
            suggestion.suggestion.locationRecommendations.length > 0 && (
              <>
                <Typography variant="subtitle1" gutterBottom>
                  Location-Based Recommendations
                </Typography>

                <Stack spacing={2} sx={{ mb: 2 }}>
                  {suggestion.suggestion.locationRecommendations.map(
                    (item, itemIndex) => (
                      <LocationRecommendationCard
                        key={`location-${itemIndex}`}
                        item={item}
                      />
                    ),
                  )}
                </Stack>
              </>
            )}
        </Box>
      ))}
    </Card>
  );
}

type ActionItemCardProps = {
  item: ActionItem;
};

function ActionItemCard({ item }: ActionItemCardProps) {
  const theme = useTheme();

  const getPriorityColor = (priority: string) => {
    switch (priority) {
      case 'high':
        return theme.palette.error.main;
      case 'medium':
        return theme.palette.warning.main;
      case 'low':
        return theme.palette.success.main;
      default:
        return theme.palette.info.main;
    }
  };

  const getTypeIcon = (type: string) => {
    switch (type.toLowerCase()) {
      case 'exercise':
        return 'mdi:run';
      case 'nutrition':
        return 'mdi:food-apple';
      case 'health':
        return 'mdi:heart-pulse';
      case 'grooming':
        return 'mdi:content-cut';
      case 'training':
        return 'mdi:school';
      default:
        return 'mdi:paw';
    }
  };

  return (
    <Card
      sx={{
        p: 2,
        boxShadow: theme.customShadows.z1,
        borderLeft: `4px solid ${getPriorityColor(item.priority)}`,
        bgcolor: alpha(getPriorityColor(item.priority), 0.05),
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}>
        <Iconify
          icon={getTypeIcon(item.type)}
          sx={{
            color: getPriorityColor(item.priority),
            width: 24,
            height: 24,
            mt: 0.5,
          }}
        />
        <Box sx={{ flexGrow: 1 }}>
          <Box
            sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}
          >
            <Typography
              variant="subtitle2"
              sx={{ textTransform: 'capitalize' }}
            >
              {item.type}
            </Typography>
            <Chip
              label={item.priority}
              size="small"
              color={
                item.priority === 'high'
                  ? 'error'
                  : item.priority === 'medium'
                  ? 'warning'
                  : 'success'
              }
              sx={{ height: 20, fontSize: '0.625rem' }}
            />
          </Box>
          <Typography variant="body2">{item.description}</Typography>
        </Box>
      </Box>
    </Card>
  );
}

type RecommendationCardProps = {
  item: Recommendation;
};

function RecommendationCard({ item }: RecommendationCardProps) {
  const theme = useTheme();

  const getTypeIcon = (type: string) => {
    switch (type.toLowerCase()) {
      case 'health':
        return 'mdi:heart-pulse';
      case 'training':
        return 'mdi:school';
      case 'social':
        return 'mdi:account-group';
      case 'environment':
        return 'mdi:home';
      case 'toys':
        return 'mdi:toy-brick';
      default:
        return 'mdi:information';
    }
  };

  return (
    <Card sx={{ p: 2, boxShadow: theme.customShadows.z1 }}>
      <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}>
        <Iconify
          icon={getTypeIcon(item.type)}
          sx={{
            color: theme.palette.info.main,
            width: 24,
            height: 24,
            mt: 0.5,
          }}
        />
        <Box sx={{ flexGrow: 1 }}>
          <Typography
            variant="subtitle2"
            sx={{ textTransform: 'capitalize', mb: 0.5 }}
          >
            {item.type}
          </Typography>
          <Typography variant="body2">{item.description}</Typography>
        </Box>
      </Box>
    </Card>
  );
}

type LocationRecommendationCardProps = {
  item: LocationRecommendation;
};

function LocationRecommendationCard({ item }: LocationRecommendationCardProps) {
  const theme = useTheme();

  const getCategoryIcon = (category: string) => {
    switch (category) {
      case 'weather':
        return 'mdi:weather-partly-cloudy';
      case 'seasonal':
        return 'mdi:calendar-month';
      case 'local_spots':
        return 'mdi:map-marker';
      case 'infection_risk':
        return 'mdi:virus';
      default:
        return 'mdi:information';
    }
  };

  const getCategoryColor = (category: string) => {
    switch (category) {
      case 'weather':
        return theme.palette.info.main;
      case 'seasonal':
        return theme.palette.warning.main;
      case 'local_spots':
        return theme.palette.success.main;
      case 'infection_risk':
        return theme.palette.error.main;
      default:
        return theme.palette.primary.main;
    }
  };

  return (
    <Card
      sx={{
        p: 2,
        boxShadow: theme.customShadows.z1,
        borderLeft: `4px solid ${getCategoryColor(item.category)}`,
        bgcolor: alpha(getCategoryColor(item.category), 0.05),
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}>
        <Iconify
          icon={getCategoryIcon(item.category)}
          sx={{
            color: getCategoryColor(item.category),
            width: 24,
            height: 24,
            mt: 0.5,
          }}
        />
        <Box sx={{ flexGrow: 1 }}>
          <Box
            sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}
          >
            <Typography
              variant="subtitle2"
              sx={{ textTransform: 'capitalize' }}
            >
              {item.type}
            </Typography>
            <Chip
              label={item.category.replace('_', ' ')}
              size="small"
              sx={{
                height: 20,
                fontSize: '0.625rem',
                backgroundColor: alpha(getCategoryColor(item.category), 0.2),
                color: getCategoryColor(item.category),
              }}
            />
          </Box>
          <Typography variant="body2" sx={{ mb: 1 }}>
            {item.description}
          </Typography>
          <Typography
            variant="caption"
            sx={{ color: 'text.secondary', fontStyle: 'italic' }}
          >
            {item.relevance}
          </Typography>
        </Box>
      </Box>
    </Card>
  );
}
