// @mui
import {
  Box,
  Breadcrumbs,
  Link,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
//
import { useResponsiveBreakpoints } from 'src/hooks/useResponsiveBreakpoints';
import LinkItem from './LinkItem';
import { CustomBreadcrumbsProps } from './types';

// ----------------------------------------------------------------------

export default function CustomBreadcrumbs({
  links,
  action,
  heading,
  moreLink,
  activeLast,
  sx,
  ...other
}: CustomBreadcrumbsProps) {
  const theme = useTheme();
  const lastLink = links[links.length - 1].name;
  const { isSm, isMd, isLg } = useResponsiveBreakpoints();
  const matchesQuery = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      sx={{
        mb: 3,
        paddingX: isSm || isMd || isLg ? 2 : 0,
        paddingTop: isSm || isMd || isLg ? 3 : 0,
        ...sx,
      }}
    >
      <Stack
        sx={{
          flexDirection: matchesQuery ? 'column' : 'row',
          gap: 3,
          alignItems: matchesQuery ? 'flex-start' : 'center',
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          {/* HEADING */}
          {heading && (
            <Typography variant="h4" gutterBottom>
              {heading}
            </Typography>
          )}

          {/* BREADCRUMBS */}
          {!!links.length && (
            <Breadcrumbs separator={<Separator />} {...other}>
              {links.map((link) => (
                <LinkItem
                  key={link.name || ''}
                  link={link}
                  activeLast={activeLast}
                  disabled={link.name === lastLink}
                />
              ))}
            </Breadcrumbs>
          )}
        </Box>

        {action && (
          <Box sx={{ flexShrink: 0, width: matchesQuery ? '100%' : 'inherit' }}>
            {' '}
            {action}{' '}
          </Box>
        )}
      </Stack>

      {/* MORE LINK */}
      {!!moreLink && (
        <Box sx={{ mt: 2 }}>
          {moreLink.map((href) => (
            <Link
              noWrap
              key={href}
              href={href}
              variant="body2"
              target="_blank"
              rel="noopener"
              sx={{ display: 'table' }}
            >
              {href}
            </Link>
          ))}
        </Box>
      )}
    </Box>
  );
}

// ----------------------------------------------------------------------

function Separator() {
  return (
    <Box
      component="span"
      sx={{
        width: 4,
        height: 4,
        borderRadius: '50%',
        bgcolor: 'text.disabled',
      }}
    />
  );
}
