import { FeedbackApi, CreateFeedbackDto } from 'woofwoof-api';
import client, { axiosBaseUrl } from './client';

// Create the base API instance
const baseFeedbackApi = new FeedbackApi(
  {
    basePath: axiosBaseUrl,
    isJsonMime: () => false,
  },
  undefined,
  //@ts-ignore
  client,
);

// Create a typed wrapper around the API
export const feedbackApi = {
  // Submit feedback for a suggestion/fact
  submitFeedback: async (data: CreateFeedbackDto) => {
    const response = await baseFeedbackApi.feedbackControllerSubmitFeedback({
      createFeedbackDto: data,
    });
    return response;
  },

  // Check if user has given feedback for a suggestion/fact
  hasGivenFeedback: async (suggestionId: string): Promise<boolean> => {
    try {
      // Use direct axios client instead of the generated API
      const response = await client.get(`/api/feedback/has-given/${suggestionId}`);
      return !!response.data.hasGiven;
    } catch (error) {
      console.error('Error checking feedback status:', error);
      return false;
    }
  },
};
