import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
import DashboardLayout from '../layouts/dashboard';
//
import RolesGuard from 'src/auth/RolesGuard';
import { useAuthContext } from 'src/auth/useAuthContext';
import CompactLayout from 'src/layouts/compact/CompactLayout';
import LiveVisitPage from 'src/pages/dashboard/LiveVisitPage';
import ProfilesTabsListPage from 'src/pages/dashboard/ProfilesTabsListPage';
import ProfilesTabsUpcomingVisitsPage from 'src/pages/dashboard/ProfilesTabsUpcomingVisitsPage';
import ProfilesTabsSuggestionsPage from '../pages/dashboard/ProfilesTabsSuggestionsPage';
import TemplatesPage from 'src/pages/dashboard/TemplatesPage';
import ConversationModal from 'src/sections/@dashboard/conversation/components/ConversationModal';
import { ConversationModalProvider } from 'src/sections/@dashboard/conversation/context/ConversationModalContext';
import { MicrophoneAndCameraPermissionsModalProvider } from 'src/sections/@dashboard/conversation/context/MicrophoneAndCameraPermissionsModalProvider';
import { CurrentVisitProvider } from 'src/sections/@dashboard/visit/context/CurrentVisitContext';
import VerificationRouter from 'src/utils/verificationRouter';
import ComingSoonPage from '../pages/ComingSoonPage';
import AccountTabsBusinessPage from '../pages/dashboard/AccountTabsBusinessPage';
import AccountTabsGeneralPage from '../pages/dashboard/AccountTabsGeneralPage';
import AccountTabsPasswordPage from '../pages/dashboard/AccountTabsPasswordPage';
import ProfileTabsFilesPage from '../pages/dashboard/ProfileTabsFilesPage';
import ProfileTabsNotesPage from '../pages/dashboard/ProfileTabsNotesPage';
import ProfileTabsVisitPage from '../pages/dashboard/ProfileTabsVisitPage';
import ProfileTabsVisitsHistoryPage from '../pages/dashboard/ProfileTabsVisitsHistoryPage';
import AccountTabsFactPreferencesPage from '../pages/dashboard/AccountTabsFactPreferencesPage';
import {
  AccountPage,
  //
  BookingCompletedPage,
  BookingPage,
  CalendarPage,
  ConfirmationPage,
  CouponCodesPage,
  FirstBookingPage,
  LoginPage,
  MaintenancePage,
  Page403,
  Page404,
  //
  Page500,
  PermissionDeniedPage,
  PetsyBookingCompletedPage,
  PetsyBookingPage,
  ProfessionalRegisterPage,
  ProfessionalSchedulePage,
  ProfilePage,
  ProfilesPage,
  QuickBookingPage,
  RegisterPage,
  ResetPasswordPage,
  ResetPasswordRequestSentPage,
  UpdateAccountPage,
  VisitsHistoryPage,
  WaitingRoomPage,
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  const { user } = useAuthContext();
  const passwordProvider = user?.providerData[0]?.providerId === 'password';

  return useRoutes([
    // Auth
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
        {
          path: 'professional-register',
          element: (
            <GuestGuard>
              <ProfessionalRegisterPage />
            </GuestGuard>
          ),
        },
        {
          path: 'confirmation',
          element: (
            <GuestGuard>
              <ConfirmationPage />
            </GuestGuard>
          ),
        },
        {
          element: <CompactLayout />,
          children: [
            {
              path: 'verify',
              element: (
                <GuestGuard>
                  <VerificationRouter />
                </GuestGuard>
              ),
            },
            {
              path: 'reset-password',
              element: <ResetPasswordPage />,
            },
            { path: 'request-sent', element: <ResetPasswordRequestSentPage /> },
          ],
        },
      ],
    },

    // Booking
    {
      path: 'booking',
      children: [
        {
          path: 'book',
          element: (
            <GuestGuard>
              <QuickBookingPage />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
        {
          path: 'professional-register',
          element: (
            <GuestGuard>
              <ProfessionalRegisterPage />
            </GuestGuard>
          ),
        },
        {
          path: 'confirmation',
          element: (
            <GuestGuard>
              <ConfirmationPage />
            </GuestGuard>
          ),
        },
        {
          element: <CompactLayout />,
          children: [
            {
              path: 'verify',
              element: (
                <GuestGuard>
                  <VerificationRouter />
                </GuestGuard>
              ),
            },
            {
              path: 'reset-password',
              element: <ResetPasswordPage />,
            },
            { path: 'request-sent', element: <ResetPasswordRequestSentPage /> },
          ],
        },
      ],
    },

    // Dashboard
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <MicrophoneAndCameraPermissionsModalProvider>
            <CurrentVisitProvider>
              <ConversationModalProvider>
                <ConversationModal />
                <DashboardLayout />
              </ConversationModalProvider>
            </CurrentVisitProvider>
          </MicrophoneAndCameraPermissionsModalProvider>
        </AuthGuard>
      ),
      children: [
        { path: 'update-account', element: <UpdateAccountPage /> },
        {
          path: 'account',
          element: <AccountPage />,
          children: [
            { path: 'general', element: <AccountTabsGeneralPage /> },
            {
              path: 'business',
              element: (
                <RolesGuard roles={['PROFESSIONAL']}>
                  <AccountTabsBusinessPage />
                </RolesGuard>
              ),
            },
            ...(passwordProvider
              ? [{ path: 'password', element: <AccountTabsPasswordPage /> }]
              : []),
            { path: 'fact-preferences', element: <AccountTabsFactPreferencesPage /> },
            { path: '*', element: <Navigate to="general" /> },
          ],
        },
        {
          path: 'profiles',
          element: <Navigate to="list" />,
        },
        {
          path: 'profiles/*',
          element: (
            <RolesGuard
              roles={['USER']}
              onPermissionDenied={() => <Navigate to="waiting-room" />}
            >
              <ProfilesPage />
            </RolesGuard>
          ),
          children: [
            {
              path: 'list',
              element: (
                <RolesGuard roles={['USER']}>
                  <ProfilesTabsListPage />
                </RolesGuard>
              ),
            },
            {
              path: 'upcoming-visits',
              element: (
                <RolesGuard roles={['USER']}>
                  <ProfilesTabsUpcomingVisitsPage />
                </RolesGuard>
              ),
            },
            {
              path: 'coupon-codes',
              element: (
                <RolesGuard roles={['USER']}>
                  <CouponCodesPage />
                </RolesGuard>
              ),
            },
            {
              path: 'suggestions',
              element: (
                <RolesGuard roles={['USER']}>
                  <ProfilesTabsSuggestionsPage />
                </RolesGuard>
              ),
            },
            {
              path: '*',
              element: <Navigate to="list" />,
            },
          ],
        },
        {
          path: 'profile/:profileId',
          element: <ProfilePage />,
          children: [
            {
              path: 'visit',
              element: (
                <RolesGuard
                  roles={['PROFESSIONAL']}
                  onPermissionDenied={() => <Navigate to="visits-history" />}
                >
                  <ProfileTabsVisitPage />
                </RolesGuard>
              ),
            },
            {
              path: 'notes',
              element: (
                <RolesGuard
                  roles={['PROFESSIONAL']}
                  onPermissionDenied={() => <Navigate to="visits-history" />}
                >
                  {' '}
                  <ProfileTabsNotesPage />
                </RolesGuard>
              ),
            },
            {
              path: 'visits-history',
              element: <ProfileTabsVisitsHistoryPage />,
            },
            { path: 'files', element: <ProfileTabsFilesPage /> },
            { path: 'suggestions', element: <ProfilesTabsSuggestionsPage /> },
            {
              path: '*',
              element: (
                <RolesGuard
                  roles={['PROFESSIONAL']}
                  onPermissionDenied={() => <Navigate to="visits-history" />}
                >
                  <Navigate to="visit" />
                </RolesGuard>
              ),
            },
          ],
        },
        {
          path: 'booking/schedule/:profileId',
          element: (
            <RolesGuard
              roles={['USER']}
              onPermissionDenied={() => <Navigate to="waiting-room" />}
            >
              <BookingPage />
            </RolesGuard>
          ),
        },
        {
          path: 'booking/first-schedule',
          element: (
            <RolesGuard
              roles={['USER']}
              onPermissionDenied={() => <Navigate to="waiting-room" />}
            >
              <FirstBookingPage />
            </RolesGuard>
          ),
        },
        {
          path: 'booking/schedule/:profileId/:visitId',
          element: (
            <RolesGuard
              roles={['USER']}
              onPermissionDenied={() => <Navigate to="waiting-room" />}
            >
              <BookingPage />
            </RolesGuard>
          ),
        },
        // PETSY ROUTES
        {
          path: 'booking/petsy-schedule/:profileId',
          element: (
            <RolesGuard
              roles={['USER']}
              onPermissionDenied={() => <Navigate to="waiting-room" />}
            >
              <PetsyBookingPage />
            </RolesGuard>
          ),
        },
        {
          path: 'booking/petsy-schedule/:profileId/:visitId',
          element: (
            <RolesGuard
              roles={['USER']}
              onPermissionDenied={() => <Navigate to="waiting-room" />}
            >
              <PetsyBookingPage />
            </RolesGuard>
          ),
        },
        {
          path: 'booking/petsy-completed',
          element: (
            <RolesGuard
              roles={['USER']}
              onPermissionDenied={() => <Navigate to="waiting-room" />}
            >
              <BookingCompletedPage />
            </RolesGuard>
          ),
        },
        // Przelewy24
        {
          path: 'booking/petsy-completed/:visitId',
          element: (
            <RolesGuard
              roles={['USER']}
              onPermissionDenied={() => <Navigate to="waiting-room" />}
            >
              <PetsyBookingCompletedPage />
            </RolesGuard>
          ),
        },
        // Autopay
        {
          path: 'booking/completed',
          element: (
            <RolesGuard
              roles={['USER']}
              onPermissionDenied={() => <Navigate to="waiting-room" />}
            >
              <PetsyBookingCompletedPage />
            </RolesGuard>
          ),
        },
        // Przelewy24
        {
          path: 'booking/completed/:visitId',
          element: (
            <RolesGuard
              roles={['USER']}
              onPermissionDenied={() => <Navigate to="waiting-room" />}
            >
              <BookingCompletedPage />
            </RolesGuard>
          ),
        },
        {
          path: 'live-visit/:visitId',
          element: (
            <RolesGuard
              roles={['USER']}
              onPermissionDenied={() => <Navigate to="waiting-room" />}
            >
              <LiveVisitPage />
            </RolesGuard>
          ),
        },
        {
          path: 'waiting-room',
          element: (
            <RolesGuard
              roles={['PROFESSIONAL']}
              onPermissionDenied={() => <Navigate to="profiles" />}
            >
              <WaitingRoomPage />
            </RolesGuard>
          ),
        },
        {
          path: 'visits-history',
          element: (
            <RolesGuard
              roles={['PROFESSIONAL']}
              onPermissionDenied={() => <Navigate to="profiles" />}
            >
              <VisitsHistoryPage />
            </RolesGuard>
          ),
        },
        {
          path: 'calendar',
          element: (
            <RolesGuard
              roles={['PROFESSIONAL']}
              onPermissionDenied={() => <Navigate to="profiles" />}
            >
              <CalendarPage />
            </RolesGuard>
          ),
        },
        {
          path: 'professional-schedule',
          element: (
            <RolesGuard
              roles={['PROFESSIONAL']}
              onPermissionDenied={() => <Navigate to="profiles" />}
            >
              <ProfessionalSchedulePage />
            </RolesGuard>
          ),
        },
        {
          path: 'visits',
          children: [
            { path: 'live/:visitId', element: <LiveVisitPage /> },
            { path: 'waiting-room/:visitId', element: <WaitingRoomPage /> },
            {
              path: 'history',
              element: <VisitsHistoryPage />,
            },
            {
              path: 'history/:visitId',
              element: <ProfileTabsVisitPage />,
            },
          ],
        },
        { path: 'templates', element: <TemplatesPage /> },
        { path: '404', element: <Page404 /> },
        { path: 'permission-denied', element: <PermissionDeniedPage /> },
        { path: '401', element: <Page403 /> },
        { path: '*', element: <Navigate to="/dashboard" replace /> },
      ],
    },
    {
      element: <CompactLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoonPage /> },
        { path: 'maintenance', element: <MaintenancePage /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
      ],
    },
    { path: '*', element: <Navigate to="/dashboard" replace /> },
  ]);
}
