import { useState, useEffect } from 'react';
// @mui
import {
  Box,
  Card,
  Stack,
  Switch,
  Typography,
  FormControlLabel,
  Chip,
  Grid,
  Button,
  CircularProgress,
  alpha,
  Theme,
} from '@mui/material';
import { useSnackbar } from '../../../../components/snackbar';
import { userApi } from 'src/api/user';
import { useUserContext } from 'src/auth/useUserContext';
import Iconify from '../../../../components/iconify';

// ----------------------------------------------------------------------

// Define a type for the color property to avoid index signature errors
type ColorType = 'error' | 'success' | 'warning' | 'info' | 'primary' | 'secondary';

const factTypeOptions = [
  { 
    value: 'medical', 
    label: 'Medyczne', 
    color: 'error' as ColorType, 
    icon: 'mdi:medical-bag',
    description: 'Fakty związane ze zdrowiem, chorobami, leczeniem lub opieką medyczną nad zwierzętami'
  },
  { 
    value: 'lifestyle', 
    label: 'Styl życia', 
    color: 'success' as ColorType, 
    icon: 'mdi:home-heart',
    description: 'Fakty dotyczące codziennej opieki, karmienia, pielęgnacji lub życia ze zwierzętami'
  },
  { 
    value: 'behavioral', 
    label: 'Behawioralne', 
    color: 'warning' as ColorType, 
    icon: 'mdi:paw',
    description: 'Fakty o zachowaniu zwierząt, treningu lub komunikacji'
  },
  { 
    value: 'psychological', 
    label: 'Psychologiczne', 
    color: 'info' as ColorType, 
    icon: 'mdi:brain',
    description: 'Fakty o emocjach zwierząt, zdrowiu psychicznym lub zdolnościach poznawczych'
  },
];

export default function AccountFactPreferences() {
  const { enqueueSnackbar } = useSnackbar();
  const { data: user, refetch } = useUserContext();
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Type assertion to handle the missing property in the type definition
    const userFactPreferences = (user as any)?.factPreferences;
    if (userFactPreferences) {
      setSelectedTypes(userFactPreferences);
    } else {
      // Default to all types if not set
      setSelectedTypes(['medical', 'lifestyle', 'behavioral', 'psychological']);
    }
  }, [user]);

  const handleToggleType = (type: string) => {
    setSelectedTypes((prev) => {
      if (prev.includes(type)) {
        // Don't allow removing the last type
        if (prev.length === 1) {
          enqueueSnackbar('Musisz wybrać przynajmniej jeden typ faktów', { variant: 'warning' });
          return prev;
        }
        return prev.filter((t) => t !== type);
      } else {
        return [...prev, type];
      }
    });
  };

  const handleSavePreferences = async () => {
    try {
      setLoading(true);
      await userApi.userControllerUpdateFactPreferences({ 
        updateFactPreferencesDto: { preferences: selectedTypes } 
      });
      enqueueSnackbar('Preferencje faktów zostały zaktualizowane', { variant: 'success' });
      refetch(); // Refresh user data
    } catch (error) {
      console.error('Error updating fact preferences:', error);
      enqueueSnackbar('Nie udało się zaktualizować preferencji faktów', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card sx={{ p: 3 }}>
      <Typography variant="h6" sx={{ mb: 3 }}>
        Preferencje faktów o zwierzętach
      </Typography>

      <Typography variant="body2" sx={{ mb: 3, color: 'text.secondary' }}>
        Wybierz typy faktów o zwierzętach, które chcesz otrzymywać. Możesz wybrać więcej niż jeden typ.
      </Typography>

      <Grid container spacing={3} sx={{ mb: 3 }}>
        {factTypeOptions.map((type) => {
          const isSelected = selectedTypes.includes(type.value);
          return (
            <Grid item xs={12} md={6} key={type.value}>
              <Card 
                sx={{ 
                  p: 2, 
                  cursor: 'pointer',
                  border: isSelected ? `2px solid ${(theme: Theme) => theme.palette[type.color].main}` : 'none',
                  bgcolor: isSelected ? (theme: Theme) => alpha(theme.palette[type.color].main, 0.08) : 'background.paper',
                  transition: 'all 0.2s',
                  '&:hover': {
                    bgcolor: (theme: Theme) => alpha(theme.palette[type.color].main, 0.12),
                  }
                }}
                onClick={() => handleToggleType(type.value)}
              >
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Box 
                    sx={{ 
                      width: 40, 
                      height: 40, 
                      display: 'flex', 
                      alignItems: 'center', 
                      justifyContent: 'center',
                      borderRadius: '50%',
                      bgcolor: (theme: Theme) => alpha(theme.palette[type.color].main, 0.12),
                      color: (theme: Theme) => theme.palette[type.color].main
                    }}
                  >
                    <Iconify icon={type.icon} width={24} />
                  </Box>
                  <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="subtitle2">{type.label}</Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      {type.description}
                    </Typography>
                  </Box>
                  <Switch
                    checked={isSelected}
                    onChange={(e) => {
                      e.stopPropagation(); // Prevent event from bubbling up to the card
                      handleToggleType(type.value);
                    }}
                    onClick={(e) => e.stopPropagation()} // Prevent click from reaching the card
                  />
                </Stack>
              </Card>
            </Grid>
          );
        })}
      </Grid>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          onClick={handleSavePreferences}
          disabled={loading}
          startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null}
        >
          Zapisz preferencje
        </Button>
      </Box>
    </Card>
  );
}
