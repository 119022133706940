import {
  Stack,
  Typography,
  useTheme,
  Tabs,
  Tab,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  CircularProgress,
  IconButton,
} from '@mui/material';
import { useState } from 'react';
import Editor from 'src/components/editor';
import Label from 'src/components/label';
import { useCurrentVisitContext } from '../hooks/useCurrentVisitContext';
import { LIGHT_TEXT } from 'src/theme/palette';
import { templateApi, Categories } from 'src/api/templates';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'src/components/snackbar';
import { useUserContext } from 'src/auth/useUserContext';
import { GetVisitByIdCategoryEnum } from 'woofwoof-api';
import Iconify from 'src/components/iconify';

// ---------------------------------------------------------------------
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
export default function VisitNotes() {
  const theme = useTheme();
  const { setVisitFlow, visitFlow, data: visitData } = useCurrentVisitContext();
  const { isProfessionalRole } = useUserContext();
  const { enqueueSnackbar } = useSnackbar();
  const textWithoutTags = visitFlow.notes.replace(/<(.|\n)*?>/g, '').trim();
  const [value, setValue] = useState(0);
  const [selectedTemplate, setSelectedTemplate] = useState('');

  // Get the visit category from the visit data
  const visitCategory = visitData?.category as
    | GetVisitByIdCategoryEnum
    | undefined;

  // Map the visit category to the template category
  const mapCategoryToTemplateCategory = (
    category?: GetVisitByIdCategoryEnum,
  ): Categories | undefined => {
    if (!category) return undefined;

    switch (category) {
      case GetVisitByIdCategoryEnum.Veterinarian:
        return Categories.veterinarian;
      case GetVisitByIdCategoryEnum.Dietitian:
        return Categories.dietitian;
      case GetVisitByIdCategoryEnum.Behaviorist:
        return Categories.behaviorist;
      case GetVisitByIdCategoryEnum.Physiotherapist:
        return Categories.physiotherapist;
      default:
        return undefined;
    }
  };

  const templateCategory = mapCategoryToTemplateCategory(visitCategory);

  // If we don't have a category from the visit, use a default one
  const effectiveCategory = templateCategory || Categories.veterinarian;

  // Query to fetch templates based on the visit's category or default
  const { data: templates = [], isLoading: isLoadingTemplates } = useQuery(
    ['templates', effectiveCategory],
    () => templateApi.getTemplates({ category: effectiveCategory }),
    {
      enabled: isProfessionalRole,
      staleTime: 1000 * 60 * 5, // 5 minutes
    },
  );

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleTemplateChange = async (event: SelectChangeEvent<string>) => {
    const templateId = event.target.value;
    setSelectedTemplate(templateId);

    if (!templateId) return;

    try {
      const template: any = await templateApi.getTemplateById(templateId);
      setVisitFlow((prev) => ({ ...prev, notes: template.content }));
      enqueueSnackbar('Szablon został zastosowany', { variant: 'success' });
    } catch (error) {
      console.error('Error applying template:', error);
      enqueueSnackbar('Nie udało się załadować szablonu', { variant: 'error' });
    }
  };

  return (
    <Stack
      sx={{
        gap: 1,
        backgroundColor: theme.palette.background.default,
        borderRadius: 2,
        boxShadow: theme.customShadows.z8,
      }}
    >
      <Stack
        sx={{
          borderTopRightRadius: theme.spacing(2),
          borderTopLeftRadius: theme.spacing(2),
          gap: 0.5,
          paddingX: 3,
          paddingY: 2,
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <Stack
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h6">Notatki</Typography>
          {!textWithoutTags.length ? (
            <Label color="error">Obowiązkowe</Label>
          ) : (
            <Label color="success">Gotowe</Label>
          )}
        </Stack>
        <Typography variant="body2" color="text.secondary">
          Miejsce do sporządzania notatek dotyczących e-spotkania.
        </Typography>
      </Stack>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          sx={{ px: 2 }}
          value={value}
          onChange={handleChange}
          aria-label="notes tabs"
        >
          <Tab label="Dla pacjenta" {...a11yProps(0)} />
          <Tab label="Wewnętrzne" {...a11yProps(1)} />
        </Tabs>
      </Box>

      {/* Template Selector - Only visible for professionals and only when on patient notes tab */}
      {isProfessionalRole && value === 0 && (
        <Box sx={{ px: 3, pt: 2 }}>
          <Stack direction="row" spacing={1} alignItems="center">
            <FormControl fullWidth variant="outlined" size="small">
              <InputLabel id="template-select-label">
                Wybierz szablon
              </InputLabel>
              <Select
                labelId="template-select-label"
                id="template-select"
                value={selectedTemplate}
                onChange={handleTemplateChange}
                label="Wybierz szablon"
                disabled={isLoadingTemplates}
                startAdornment={
                  isLoadingTemplates ? (
                    <CircularProgress size={20} sx={{ mr: 1 }} />
                  ) : null
                }
              >
                <MenuItem value="">
                  <em>Nie wybrano</em>
                </MenuItem>
                {templates.map((template) => (
                  <MenuItem key={template.id} value={template.id}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{ width: '100%' }}
                    >
                      <Typography>{template.name}</Typography>
                    </Stack>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Box>
      )}

      <CustomTabPanel value={value} index={0}>
        <Stack
          sx={{
            paddingX: 3,
            paddingY: 2,
            paddingTop: 1,
            gap: 2,
          }}
        >
          <Editor
            simple
            id="simple-editor"
            value={visitFlow.notes}
            onChange={(_value) => {
              if (_value.replace(/<(.|\n)*?>/g, '').trim()) {
                setVisitFlow((prev) => ({ ...prev, notes: _value }));
              } else {
                setVisitFlow((prev) => ({ ...prev, notes: '' }));
              }
            }}
          />
          <Typography sx={{ color: LIGHT_TEXT.secondary }} variant="caption">
            Notatki będą widoczne dla pacjenta
          </Typography>
        </Stack>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Stack
          sx={{
            paddingX: 3,
            paddingY: 2,
            paddingTop: 1,
            gap: 2,
          }}
        >
          <Editor
            simple
            id="simple-editor"
            sx={{ backgroundColor: 'warning.lightest' }}
            value={visitFlow.internalNotes}
            onChange={(_value) => {
              if (_value.replace(/<(.|\n)*?>/g, '').trim()) {
                setVisitFlow((prev) => ({ ...prev, internalNotes: _value }));
              } else {
                setVisitFlow((prev) => ({ ...prev, internalNotes: '' }));
              }
            }}
          />
          <Typography sx={{ color: LIGHT_TEXT.secondary }} variant="caption">
            Notatki wewnętrzne widoczne tylko dla Ciebie
          </Typography>
        </Stack>
      </CustomTabPanel>
    </Stack>
  );
}
