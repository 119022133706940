import { Box, Tabs, Tab, Typography, Button, Stack, FormControl, InputLabel, MenuItem, Select, CircularProgress, SelectChangeEvent } from '@mui/material';
import { useState, useEffect, ReactNode } from 'react';
import { useUserContext } from 'src/auth/useUserContext';
import Markdown from 'src/components/markdown/Markdown';
import Image from 'src/components/image/Image';
import Editor from 'src/components/editor';
import { visitApi } from 'src/api/visit';
import { useSnackbar } from 'src/components/snackbar';
import Iconify from 'src/components/iconify/Iconify';
import { templateApi, TemplateType, Categories } from 'src/api/templates';
import { useQuery } from '@tanstack/react-query';

type VisitModalNotesDetailsProps = {
  visit?: any;
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function VisitModalTabs({ visit }: VisitModalNotesDetailsProps) {
  const [value, setValue] = useState(0);
  const { isProfessionalRole } = useUserContext();
  const [isEditing, setIsEditing] = useState(false);
  const [notes, setNotes] = useState(visit.notes || '');
  const [internalNotes, setInternalNotes] = useState(visit.internalNotes || '');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedTemplate, setSelectedTemplate] = useState('');

  // Query to fetch templates based on the visit category
  const {
    data: templates = [],
    isLoading: isLoadingTemplates,
  } = useQuery(
    ['templates', visit.category],
    () => templateApi.getTemplates({ category: visit.category as Categories }),
    {
      enabled: isEditing && isProfessionalRole,
      staleTime: 1000 * 60 * 5, // 5 minutes
    }
  );

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setNotes(visit.notes || '');
    setInternalNotes(visit.internalNotes || '');
    setIsEditing(false);
    setSelectedTemplate('');
  };

  const handleSave = async () => {
    try {
      setIsSubmitting(true);
      await visitApi.visitControllerAddNotes({
        visitId: visit.id,
        addNotesDto: {
          notes,
          internalNotes,
        },
      });
      enqueueSnackbar('Notatki zostały zaktualizowane', { variant: 'success' });
      setIsEditing(false);
      setSelectedTemplate('');

      visit.notes = notes;
      visit.internalNotes = internalNotes;
    } catch (error) {
      console.error('Error updating notes:', error);
      enqueueSnackbar('Wystąpił błąd podczas aktualizacji notatek', { variant: 'error' });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleTemplateChange = async (event: SelectChangeEvent<string>) => {
    const templateId = event.target.value;
    setSelectedTemplate(templateId);
    
    if (!templateId) return;

    try {
      const template: any = await templateApi.getTemplateById(templateId);
      // Apply template based on which tab is active
      if (value === 0) {
        setNotes(template.content);
      } else {
        setInternalNotes(template.content);
      }
    } catch (error) {
      console.error('Error applying template:', error);
      enqueueSnackbar('Nie udało się załadować szablonu', { variant: 'error' });
    }
  };

  const showEditButton = isProfessionalRole && visit.status === 'COMPLETED';

  return isProfessionalRole ? (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Tabs
          variant="fullWidth"
          value={value}
          onChange={handleChange}
          aria-label="notes tabs"
          indicatorColor="primary"
          sx={{ flexGrow: 1 }}
        >
          <Tab label="Pacjent" {...a11yProps(0)} />
          <Tab label="Specjalista" {...a11yProps(1)} />
        </Tabs>
        {showEditButton && (
          <Box sx={{ ml: 2 }}>
            {isEditing ? (
              <Stack direction="row" spacing={1}>
                <Button 
                  variant="outlined" 
                  color="inherit" 
                  onClick={handleCancel}
                  disabled={isSubmitting}
                >
                  Anuluj
                </Button>
                <Button 
                  variant="contained" 
                  color="primary" 
                  onClick={handleSave}
                  disabled={isSubmitting}
                  startIcon={<Iconify icon="eva:save-fill" />}
                >
                  Zapisz
                </Button>
              </Stack>
            ) : (
              <Button 
                variant="outlined" 
                color="primary" 
                onClick={handleEdit}
                startIcon={<Iconify icon="eva:edit-fill" />}
              >
                Edytuj
              </Button>
            )}
          </Box>
        )}
      </Box>

      {/* Template Selector */}
      {isEditing && (
        <Box sx={{ mt: 2, mb: 2 }}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="template-select-label">Wybierz szablon</InputLabel>
            <Select
              labelId="template-select-label"
              id="template-select"
              value={selectedTemplate}
              onChange={handleTemplateChange}
              label="Wybierz szablon"
              disabled={isLoadingTemplates}
              startAdornment={
                isLoadingTemplates ? (
                  <CircularProgress size={20} sx={{ mr: 1 }} />
                ) : null
              }
            >
              <MenuItem value="">
                <em>Nie wybrano</em>
              </MenuItem>
              {templates.map((template) => (
                <MenuItem key={template.id} value={template.id}>
                  {template.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}

      <CustomTabPanel value={value} index={0}>
        {isEditing ? (
          <Editor
            simple
            id="edit-notes"
            value={notes}
            onChange={(value) => setNotes(value)}
          />
        ) : visit.notes ? (
          <>
            <Typography>Notatki z e-spotkania</Typography>
            <Typography color="text.secondary">
              <Markdown>{visit.notes}</Markdown>
            </Typography>
          </>
        ) : (
          <Typography sx={{ p: 6 }} variant="h6" align="center">
            <Box display="flex" justifyContent="center" alignItems="center">
              <Image
                disabledEffect
                visibleByDefault
                alt="auth"
                src={'/assets/illustrations/laptop-paw-blue.svg'}
                sx={{ maxWidth: 180, paddingBottom: 2 }}
              />
            </Box>
            Brak Notatek
          </Typography>
        )}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        {isEditing ? (
          <Editor
            simple
            id="edit-internal-notes"
            value={internalNotes}
            onChange={(value) => setInternalNotes(value)}
          />
        ) : visit.internalNotes ? (
          <>
            <Typography>Notatki z e-spotkania</Typography>
            <Typography color="text.secondary">
              <Markdown>{visit.internalNotes}</Markdown>
            </Typography>
          </>
        ) : (
          <Typography sx={{ p: 6 }} variant="h6" align="center">
            <Box display="flex" justifyContent="center" alignItems="center">
              <Image
                disabledEffect
                visibleByDefault
                alt="auth"
                src={'/assets/illustrations/laptop-paw-blue.svg'}
                sx={{ maxWidth: 180, paddingBottom: 2 }}
              />
            </Box>
            Brak Notatek
          </Typography>
        )}
      </CustomTabPanel>
    </>
  ) : (
    <>
      {visit.notes ? (
        <>
          <Typography variant="h6">Notatki z e-spotkania</Typography>
          <Typography color="text.secondary">
            <Markdown>{visit.notes}</Markdown>
          </Typography>
        </>
      ) : (
        <Typography sx={{ p: 6 }} variant="h6" align="center">
          <Box display="flex" justifyContent="center" alignItems="center">
            <Image
              disabledEffect
              visibleByDefault
              alt="auth"
              src={'/assets/illustrations/laptop-paw-blue.svg'}
              sx={{ maxWidth: 180, paddingBottom: 2 }}
            />
          </Box>
          Brak Notatek
        </Typography>
      )}
    </>
  );
}
