import { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Card,
  Table,
  Stack,
  Button,
  Tooltip,
  Divider,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  FormControlLabel,
  Switch,
  CircularProgress,
  Grid,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useSnackbar } from 'src/components/snackbar';
import Iconify from 'src/components/iconify/Iconify';
import Scrollbar from 'src/components/scrollbar/Scrollbar';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/CustomBreadcrumbs';
import {
  useTable,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TablePaginationCustom,
} from 'src/components/table';
import {
  templateApi,
  Categories,
  TemplateType,
  Template,
} from 'src/api/templates';
import Editor from 'src/components/editor';
import { useUserContext } from 'src/auth/useUserContext';
import { fDate } from 'src/utils/formatTime';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import ConfirmDialog from 'src/components/confirm-dialog/ConfirmDialog';

const TABLE_HEAD = [
  { id: 'name', label: 'Nazwa', align: 'left' },
  { id: 'description', label: 'Opis', align: 'left' },
  { id: 'category', label: 'Kategoria', align: 'left' },
  { id: 'templateType', label: 'Typ', align: 'left' },
  { id: 'updatedAt', label: 'Ostatnia aktualizacja', align: 'left' },
  { id: 'isShared', label: 'Udostępniony', align: 'center' },
  { id: '', label: 'Akcje', align: 'right' },
];

// ----------------------------------------------------------------------

export default function TemplatesPage() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { data, isProfessionalRole } = useUserContext();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const [filterName, setFilterName] = useState('');
  const [filterCategory, setFilterCategory] = useState<Categories | ''>('');
  const [filterType, setFilterType] = useState<TemplateType | ''>('');

  const [openTemplateDialog, setOpenTemplateDialog] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState<Template | null>(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [templateToDelete, setTemplateToDelete] = useState<string | null>(null);

  // Queries
  const {
    data: templates = [],
    isLoading,
  } = useQuery(['templates', filterCategory, filterType, filterName], () =>
    templateApi.getTemplates({
      category: filterCategory || undefined,
      templateType: filterType || undefined,
      name: filterName || undefined,
    }),
  );

  // Mutations
  const createTemplateMutation = useMutation({
    mutationFn: (data: any) => templateApi.createTemplate(data),
    onSuccess: () => {
      queryClient.invalidateQueries(['templates']);
      enqueueSnackbar('Szablon został utworzony', { variant: 'success' });
      handleCloseTemplateDialog();
    },
    onError: (error) => {
      console.error('Error creating template:', error);
      enqueueSnackbar('Wystąpił błąd podczas tworzenia szablonu', {
        variant: 'error',
      });
    },
  });

  const updateTemplateMutation = useMutation({
    mutationFn: ({ id, data }: { id: string; data: any }) =>
      templateApi.updateTemplate(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(['templates']);
      enqueueSnackbar('Szablon został zaktualizowany', { variant: 'success' });
      handleCloseTemplateDialog();
    },
    onError: (error) => {
      console.error('Error updating template:', error);
      enqueueSnackbar('Wystąpił błąd podczas aktualizacji szablonu', {
        variant: 'error',
      });
    },
  });

  const deleteTemplateMutation = useMutation({
    mutationFn: (id: string) => templateApi.deleteTemplate(id),
    onSuccess: () => {
      queryClient.invalidateQueries(['templates']);
      enqueueSnackbar('Szablon został usunięty', { variant: 'success' });
      setTemplateToDelete(null);
      setOpenConfirmDialog(false);
    },
    onError: (error) => {
      console.error('Error deleting template:', error);
      enqueueSnackbar('Wystąpił błąd podczas usuwania szablonu', {
        variant: 'error',
      });
    },
  });

  const handleOpenTemplateDialog = (template: Template | null = null) => {
    if (template) {
      setCurrentTemplate(template);
      setIsEditing(true);
    } else {
      setCurrentTemplate({
        name: '',
        description: '',
        category: Categories.dietitian,
        templateType: TemplateType.GENERAL,
        content: '',
        isShared: false,
        createdBy: data?.id || '',
        createdAt: new Date(),
        updatedAt: new Date(),
      });
      setIsEditing(false);
    }
    setOpenTemplateDialog(true);
  };

  const handleCloseTemplateDialog = () => {
    setOpenTemplateDialog(false);
    setCurrentTemplate(null);
  };

  const handleSubmitTemplate = async () => {
    if (!currentTemplate) return;

    const templateData = {
      name: currentTemplate.name,
      description: currentTemplate.description,
      category: currentTemplate.category,
      templateType: currentTemplate.templateType,
      content: currentTemplate.content,
      isShared: currentTemplate.isShared,
    };

    if (isEditing && currentTemplate.id) {
      updateTemplateMutation.mutate({
        id: currentTemplate.id,
        data: templateData,
      });
    } else {
      createTemplateMutation.mutate(templateData);
    }
  };

  const handleDeleteTemplate = (id: string) => {
    setTemplateToDelete(id);
    setOpenConfirmDialog(true);
  };

  const confirmDeleteTemplate = () => {
    if (templateToDelete) {
      deleteTemplateMutation.mutate(templateToDelete);
    }
  };

  const dataFiltered = templates;

  const isNotFound = !dataFiltered.length;

  const categoryOptions = Object.values(Categories).map((cat) => ({
    value: cat,
    label: cat.toString().charAt(0).toUpperCase() + cat.toString().slice(1),
  }));

  const templateTypeOptions = Object.values(TemplateType).map((type) => ({
    value: type,
    label: type.toString()
      .split('_')
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' '),
  }));

  // Check if user is not a dietitian (don't allow access to templates page)
  if (!isProfessionalRole) {
    return (
      <Container>
        <Typography variant="h4" paragraph>
          Brak dostępu
        </Typography>
        <Typography>
          Ta funkcjonalność jest dostępna tylko dla specjalistów.
        </Typography>
      </Container>
    );
  }

  return (
    <>
      <Helmet>
        <title>Szablony | Woofwoof</title>
      </Helmet>

      <Container maxWidth="xl">
        <CustomBreadcrumbs
          heading="Szablony Notatek"
          links={[
            {
              name: 'Dashboard',
              href: '/dashboard',
            },
            {
              name: 'Szablony',
            },
          ]}
          action={
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={() => handleOpenTemplateDialog()}
            >
              Nowy Szablon
            </Button>
          }
        />

        <Card>
          <Box sx={{ p: 2 }}>
            <Stack
              direction="row"
              spacing={2}
              flexWrap="wrap"
              alignItems="center"
            >
              <TextField
                fullWidth
                label="Szukaj po nazwie"
                value={filterName}
                onChange={(e) => setFilterName(e.target.value)}
                placeholder="Wpisz nazwę szablonu..."
                InputProps={{
                  startAdornment: (
                    <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', mr: 1 }} />
                  ),
                }}
                sx={{ maxWidth: 280 }}
              />

              <FormControl sx={{ width: 200 }}>
                <InputLabel id="category-select-label">Kategoria</InputLabel>
                <Select
                  labelId="category-select-label"
                  id="category-select"
                  value={filterCategory}
                  label="Kategoria"
                  onChange={(e) =>
                    setFilterCategory(e.target.value as Categories | '')
                  }
                >
                  <MenuItem value="">
                    <em>Wszystkie</em>
                  </MenuItem>
                  {categoryOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl sx={{ width: 200 }}>
                <InputLabel id="type-select-label">Typ</InputLabel>
                <Select
                  labelId="type-select-label"
                  id="type-select"
                  value={filterType}
                  label="Typ"
                  onChange={(e) =>
                    setFilterType(e.target.value as TemplateType | '')
                  }
                >
                  <MenuItem value="">
                    <em>Wszystkie</em>
                  </MenuItem>
                  {templateTypeOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          </Box>

          <Divider />

          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            {isLoading && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                  zIndex: 1,
                }}
              >
                <CircularProgress />
              </Box>
            )}

            <Scrollbar>
              <Table size={dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  onSort={onSort}
                />

                <TableBody>
                  {dataFiltered
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: Template) => (
                      <TableRow hover key={row.id}>
                        <TableCell align="left">{row.name}</TableCell>
                        <TableCell align="left">{row.description}</TableCell>
                        <TableCell align="left">
                          {row.category.toString().charAt(0).toUpperCase() +
                            row.category.toString().slice(1)}
                        </TableCell>
                        <TableCell align="left">
                          {row.templateType.toString()
                            .split('_')
                            .map(
                              (word: string) =>
                                word.charAt(0).toUpperCase() + word.slice(1),
                            )
                            .join(' ')}
                        </TableCell>
                        <TableCell align="left">
                          {fDate(new Date(row.updatedAt))}
                        </TableCell>
                        <TableCell align="center">
                          {row.isShared ? (
                            <Iconify
                              icon="eva:checkmark-circle-fill"
                              color="success.main"
                            />
                          ) : (
                            <Iconify
                              icon="eva:close-circle-fill"
                              color="error.main"
                            />
                          )}
                        </TableCell>
                        <TableCell align="right">
                          <Tooltip title="Edytuj">
                            <IconButton
                              color="primary"
                              onClick={() => handleOpenTemplateDialog(row)}
                            >
                              <Iconify icon="eva:edit-fill" />
                            </IconButton>
                          </Tooltip>

                          <Tooltip title="Usuń">
                            <IconButton
                              color="error"
                              onClick={() => handleDeleteTemplate(row.id!)}
                            >
                              <Iconify icon="eva:trash-2-outline" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}

                  <TableEmptyRows
                    height={dense ? 52 : 72}
                    emptyRows={emptyRows(page, rowsPerPage, templates.length)}
                  />

                  {isNotFound && <TableNoData isNotFound={isNotFound} />}
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={dataFiltered.length}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            dense={dense}
            onChangeDense={onChangeDense}
          />
        </Card>
      </Container>

      {/* Template Dialog */}
      <Dialog
        open={openTemplateDialog}
        onClose={handleCloseTemplateDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {isEditing ? 'Edytuj Szablon' : 'Dodaj Nowy Szablon'}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ py: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Nazwa"
                  value={currentTemplate?.name || ''}
                  onChange={(e) =>
                    setCurrentTemplate((prev) =>
                      prev ? { ...prev, name: e.target.value } : null,
                    )
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Opis"
                  value={currentTemplate?.description || ''}
                  onChange={(e) =>
                    setCurrentTemplate((prev) =>
                      prev ? { ...prev, description: e.target.value } : null,
                    )
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="category-select-label">Kategoria</InputLabel>
                  <Select
                    labelId="category-select-label"
                    id="category-select"
                    value={currentTemplate?.category || ''}
                    label="Kategoria"
                    onChange={(e) =>
                      setCurrentTemplate((prev) =>
                        prev
                          ? { ...prev, category: e.target.value as Categories }
                          : null,
                      )
                    }
                  >
                    {categoryOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="type-select-label">Typ</InputLabel>
                  <Select
                    labelId="type-select-label"
                    id="type-select"
                    value={currentTemplate?.templateType || ''}
                    label="Typ"
                    onChange={(e) =>
                      setCurrentTemplate((prev) =>
                        prev
                          ? {
                              ...prev,
                              templateType: e.target.value as TemplateType,
                            }
                          : null,
                      )
                    }
                  >
                    {templateTypeOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={currentTemplate?.isShared || false}
                      onChange={(e) =>
                        setCurrentTemplate((prev) =>
                          prev ? { ...prev, isShared: e.target.checked } : null,
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Udostępnij dla innych specjalistów"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom>
                  Treść szablonu
                </Typography>
                {currentTemplate && (
                  <Editor
                    id="template-content"
                    value={currentTemplate.content}
                    onChange={(content) =>
                      setCurrentTemplate((prev) =>
                        prev ? { ...prev, content } : null,
                      )
                    }
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTemplateDialog} color="inherit">
            Anuluj
          </Button>
          <Button
            onClick={handleSubmitTemplate}
            variant="contained"
            disabled={
              createTemplateMutation.isLoading ||
              updateTemplateMutation.isLoading
            }
          >
            {createTemplateMutation.isLoading ||
            updateTemplateMutation.isLoading ? (
              <CircularProgress size={24} />
            ) : isEditing ? (
              'Zapisz zmiany'
            ) : (
              'Dodaj szablon'
            )}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirm Delete Dialog */}
      <ConfirmDialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        title="Usuń szablon"
        content="Czy na pewno chcesz usunąć ten szablon? Ta operacja jest nieodwracalna."
        action={
          <Button
            variant="contained"
            color="error"
            onClick={confirmDeleteTemplate}
            disabled={deleteTemplateMutation.isLoading}
          >
            {deleteTemplateMutation.isLoading ? (
              <CircularProgress size={24} />
            ) : (
              'Usuń'
            )}
          </Button>
        }
      />
    </>
  );
}
