import React from 'react';
import { Helmet } from 'react-helmet-async';
import ProfileSuggestions from '../../sections/@dashboard/profile/components/ProfileSuggestions';

export default function ProfilesTabsSuggestionsPage() {
  return (
    <>
      <Helmet>
        <title>Dashboard: Suggestions | Woof Woof</title>
      </Helmet>

      <ProfileSuggestions />
    </>
  );
}
